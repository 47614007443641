import React from "react";
import { Tag, Icon } from "antd";

export const genericTag = (value: string, labelColor: string) => {

  const styles = {
    fontSize: '1.1em',
  };

  return (
    <Tag color={labelColor} style={styles}>
      {value || 'Default'}
    </Tag>
  );
};
