import React, { useState, FormEvent, useContext, useEffect } from 'react';
import { Alert, Button, Form, Icon, Input, Spin } from 'antd';
import { SessionContext } from './App';
import { postPasswordChange } from '../api/postPasswordChange';
import { getResetToken } from '../api/getResetToken';

interface WelcomeProps {
  token: string;
}

export const WelcomeForm = ({ token }: WelcomeProps) => {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [username, setUsername] = useState('');
  const [personName, setName] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [weakPassword, setWeakPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [expiredToken, setExpiredToken] = useState(true);

  const [resetFailed, setResetFailed] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [passwordSet, setPasswordSet] = useState(false);
  const { session, dispatch } = useContext(SessionContext);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'password1':
        setPassword1(value);
        break;
      case 'password2':
        setPassword2(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getResetToken(dispatch, { token }).then(response => {
      if (response.status === 404) {
        setExpiredToken(true);
      }
      return response.json()
    }).then(resetToken => {
      if (resetToken.used) {
        setExpiredToken(true);
      } else {
        setExpiredToken(false);
        setName(resetToken.name);
        setUsername(resetToken.email);
      }
      if (session && session.person && session.router) {
        session.router.go('home');
      }
    });
  }, [session, token]);

  async function handleReset(e: FormEvent) {
    e.preventDefault();


    if (password1 !== password2) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
      setWeakPassword(false);
      setSubmitted(true);
      const result = await postPasswordChange(dispatch, { username, personName, password: password1, token });
      setSubmitted(false);
      if (result.status === 400) {
        result.json().then(data => {
          if (data.type === "password") {
            setWeakPassword(true);
          } else {
            setResetFailed(true);
          }
        })
        setServerError(false);
        setPasswordSet(false);
      } else if (!result.ok) {
        setResetFailed(false);
        setServerError(true);
        setPasswordSet(false);
      } else {
        setResetFailed(false);
        setServerError(false);
        setPasswordSet(true);
      }
    }
  }

  const goBack = () => session.router.go('home');

  if ((expiredToken) || (passwordSet)) {
    return (
      <div>
        {passwordSet && (
          <Alert
            message="New Password Set"
            description="Your password has been set"
            type="success"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        {expiredToken && (
          <Alert
            message="Invite token expired"
            description="The invite token is expired. Please fill out the invitation form again."
            type="error"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}

        <Form.Item>
          <Button onClick={goBack}>
            Login
          </Button>
        </Form.Item>
      </div>
    );
  } else {
    return (
      <Spin tip="Setting new account..." spinning={submitted} indicator={<Icon type="loading" />}>
        <Form
          className="reset-form"
          onSubmit={handleReset}
        >
          {resetFailed && (
            <Alert
              message="Setting password Failed"
              description="There was an error with setting your new passwowrd. Failed to reset."
              type="error"
              style={{ marginBottom: '2em' }}
              showIcon={true}
            />
          )}
          {serverError && (
            <Alert
              message="Server Error"
              description="There was an error communicating with the server. Please try again soon."
              type="error"
              style={{ marginBottom: '2em' }}
              showIcon={true}
            />
          )}
          {passwordError && (
            <Alert
              message="Invalid Password"
              description="The two passwords do not match"
              type="error"
              style={{ marginBottom: '2em' }}
              showIcon={true}
            />
          )}
          {weakPassword && (
            <Alert
              message="Weak Password"
              description="Password cannot contain your name and requires at least 3 of the following: uppercase, lowercase, numbers, or special characters."
              type="error"
              style={{ marginBottom: '2em' }}
              showIcon={true}
            />
          )}
          <p>{personName}, please enter in your new password below</p>
          <Form.Item label="Enter Password" colon={false}>
            <Input.Password
              type="password"
              name="password1"
              required={true}
              value={password1}
              onChange={handleChange}
              prefix={<Icon type="lock" className="input-icon" />}
            />
          </Form.Item>

          <Form.Item label="Retype Password" colon={false}>
            <Input.Password
              type="password"
              name="password2"
              required={true}
              value={password2}
              onChange={handleChange}
              prefix={<Icon type="lock" className="input-icon" />}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: '1em' }}
            >
              Set Password
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}