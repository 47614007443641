import { Dispatch } from 'react';
import { request } from './request';
import { SessionAction } from '../interfaces/Session';

interface GetMFAUserInfoProps {
  token: string,
}

export function getMFAUserInfo(dispatch: Dispatch<SessionAction>, props: GetMFAUserInfoProps) {
  return request(dispatch, '/auth/mfatoken/' + props.token + '/user', {
    auth: false,
  });
}