import { WizardStep } from "../components/Wizard/Wizard";
import { RadioField } from "../components/Wizard/fields/RadioField";
import { TextField } from "../components/Wizard/fields/TextField";
import { YesNoField } from "../components/Wizard/fields/YesNoField";
import { TextAreaField } from "../components/Wizard/fields/TextAreaField";
import { FilesField } from "../components/Wizard/fields/FilesField";
import { CertifiedField } from "../components/Wizard/fields/CertifiedField";
import { CheckboxField } from "../components/Wizard/fields/CheckboxField";

export const crWizard: WizardStep[] = [
  {
    name: "type",
    title: "Select Report Type",
    error: "All required questions must be answered.",
    fields: [
      {
        name: "subtype",
        field: RadioField,
        required: true,
        label: "Select Report Type",
        options: [
          { label: "Continuing Review Report", value: "crReport" },
          { label: "Closure Report", value: "closureReport" },
        ]
      },
      {
        name: "closureReasons",
        field: CheckboxField,
        clear: true,
        show: d => !!d.field("subtype") && d.field("subtype") === "closureReport",
        required: d => !!d.field("subtype") && d.field("subtype") === "closureReport",
        label: "Indicate the reason(s) for the study closure. (Check all that apply)",
        options: [
          { label: "Change in relying site PI", value: "piChange" },
          { label: "Reached accrual goal", value: "reachedAccrualGoal" },
          { label: "Interim analysis demonstrated efficacy", value: "analysisShowedEfficiency" },
          { label: "Report in literature showed efficacy", value: "reportShowedEfficiency" },
          { label: "Completion not feasible", value: "notFeasible" },
          { label: "Loss of funding", value: "fundingLoss" },
          { label: "Other", value: "other" },
        ]
      },
      {
        name: "closureReasonOther",
        field: TextField,
        show: d => !!d.field("closureReasons") && d.field("closureReasons").indexOf("other") > -1,
        required: d => !!d.field("closureReasons") && d.field("closureReasons").indexOf("other") > -1,
        label: "If Other was checked, indicate the reason(s).",
      },
      {
        name: "closureConfirmation",
        field: YesNoField,
        label: "If a site closure has been requested, confirm the following:",
        help: "All data collection and participant involvement at this site has ended. All analysis of identifiable data at this site for the purposes of this study has been completed.",
        show: d => !!d.field("subtype") && d.field("subtype") === "closureReport",
        required: d => !!d.field("subtype") && d.field("subtype") === "closureReport",
      },
    ],
  },
  {
    name: "status",
    title: "Enrollment Status",
    error: d => {
      const isClosed = d.field("subtype") === "closureReport";
      const isEnrolling = d.field("substatus") === "enrollmentSubjects";

      if (isClosed && isEnrolling) {
        return "This study is not eligible for closure if subjects are still being enrolled. Either revise the report type to request a Continuing Review or revise the enrollment status to select Enrollment Closed.";
      }
      return "Status must be specified";
    },
    fields: [
      {
        name: "substatus",
        field: RadioField,
        required: true,
        label: "Select Report Type",
        options: [
          { label: "Enrolling Subjects", value: "enrollmentSubjects" },
          { label: "Enrollment Closed", value: "enrollmentClosed" },
          { label: "Enrollment Closed, subjects may be re-consented", value: "enrollmentReconsented" },
          { label: "Enrollment Temporarily Suspended", value: "enrollTempSuspended" },
        ],
        validate: ({ document }) => {
          const isClosed = document.field("subtype") === "closureReport";
          const isEnrolling = document.field("substatus") === "enrollmentSubjects";
          const isEmpty = document.field("substatus");
          if ((isClosed && isEnrolling) || isEmpty === undefined) {
            return false;
          }
          return true;
        }
      }
    ],
  },
  {
    name: "enrolled",
    title: "Participants Enrolled",
    fields: [
      {
        name: "enrollmentNumber",
        field: TextField,
        required: true,
        label: "# Subjects Enrolled since the last IRB (Initial or Continuing) review:",
        help: "# of subjects"
      }
    ],
  },
  {
    name: "withdrew",
    title: "Participants Withdrew",
    fields: [
      {
        name: "hasWithdrew",
        field: YesNoField,
        label: "Since the last continuing review, did any subjects at your site withdraw from the study or were any withdrawn by the investigator?",
        required: true
      },
      {
        name: "reason",
        field: TextAreaField,
        label: "Provide the number of withdrawn subjects and the reasons for the withdrawal(s)",
        show: d => !!d.field("hasWithdrew"),
        required: d => !!d.field("hasWithdrew"),
      }
    ],
  },
  {
    name: "complaints",
    title: "Registered Complaints",
    fields: [
      {
        name: "registeredComplaint",
        field: TextAreaField,
        required: true,
        label: "Since the last continuing review, have subjects at your site registered any complaints about the research or study personnel? If so, please outline the nature of these complaints."
      },
    ],
  },
  {
    name: "events",
    title: "Adverse Events",
    fields: [
      {
        name: "events",
        field: TextAreaField,
        required: true,
        label: "Provide (or attach) a summary of any noteworthy adverse events (including any that occurred with an unexpected frequency or severity), protocol deviations, or other study-related issues that occurred at your site and did not meet the criteria for prompt reporting to the CHOP IRB (e.g. Unanticipated Problems involving risk to subjects or others or non-compliance)."
      },
      {
        name: "crAdverseDoc",
        field: FilesField,
        label: "Attach a text file",
      }
    ],
  },
  {
    name: "changes",
    title: "Material Changes",
    fields: [
      {
        name: "changes",
        field: TextAreaField,
        required: true,
        label: "Have there been any material changes to the study information in the Single IRB Portal, which were not previously reported to the CHOP IRB? If so, briefly outline the nature of these changes."
      }
    ],
  },
  {
    name: "submit",
    title: "Certify & Submit",
    intro: "Confirm the following: All information submitted to the CHOP IRB is current and accurate. All Unanticipated Problems that met reporting criteria were submitted to the CHOP IRB as Reportable Events. (CHOP IRB Policies are available at https://irb.research.chop.edu/policies).",
    fields: [
      {
        name: "crOtherDocs",
        label: "Attach any other documents pertaining to this continuing review submission (e.g. cover letter / response to IRB stipulations cover letter, or monitoring report)",
        field: FilesField,
      },
      {
        name: "certified",
        label: "Certification",
        required: true,
        validate: ({ value }) => !!value,
        field: CertifiedField,
      }
    ],
  },
];