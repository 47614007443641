import React, { useContext } from "react";
import { FieldProps, Field, getFieldReadOnly } from "../Field";
import { StepContext } from "../Step";
import CheckboxGroup, { CheckboxOptionType } from "antd/lib/checkbox/Group";
import { Button } from "antd";
import { SessionContext } from "../../App";

interface OptionsFieldProps extends FieldProps {
  options: string[] | CheckboxOptionType[];
}

export const CheckboxField = (props: OptionsFieldProps) => {
  const { value, setValue } = useContext(StepContext);
  const { session } = useContext(SessionContext);

  if (!session.document) {
    return null;
  }
  
  const onClear = () => setValue([]);

  return (
    <Field {...props}>
      <CheckboxGroup
        options={props.options}
        value={value}
        disabled={getFieldReadOnly(props, session.document)}
        onChange={setValue}
      />
      { props.clear && <div><Button size="small" onClick={onClear}>Clear</Button></div>}
    </Field>
  );
}