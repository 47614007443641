import { TextField } from "../components/Wizard/fields/TextField";
import { WizardStep } from "../components/Wizard/Wizard";
import { LinkedDocuments } from "../components/Wizard/fields/LinkedDocuments";
import { RadioField } from "../components/Wizard/fields/RadioField";
import { TextAreaField } from "../components/Wizard/fields/TextAreaField";
import { FilesField } from "../components/Wizard/fields/FilesField";
import { phoneRegex, zipRegex, emailRegex } from "../helpers/regex";

export const scWizard: WizardStep[] = [
  {
    name: 'info',
    title: 'Study Coordinator Profile',
    error: 'Email and phone number must be specified',
    fields: [
      {
        name: 'name',
        label: 'Name',
        readOnly: true,
        field: TextField,
      },
      {
        name: 'institution',
        label: 'Institution(s)',
        readOnly: true,
        field: LinkedDocuments,
      },
      {
        name: 'email',
        label: 'Email',
        required: true,
        field: TextField,
        validate: ({ value }) => !value || emailRegex.test(value),
      },
      {
        name: 'phone',
        label: 'Phone',
        required: true,
        field: TextField,
        validate: ({ value }) => phoneRegex.test(value),
      },
    ],
  },
];
