import React, { useEffect, createContext, useReducer } from 'react';
import { useRoutes } from '../hooks/useRoutes';
import { routes, MyRouteSpec } from '../routes';
import { PublicLayout } from './PublicLayout';
import { Session, SessionAction } from '../interfaces/Session';
import { Layout } from './Layout';
import { DocumentCollection } from '../collections/DocumentCollection';
import { Document } from '../resources/Document';
import { getThicket } from '../api/getThicket';
import { LoginForm } from './LoginForm';
import { getSettings } from '../api/getSettings';

function sessionReducer(state: Session<MyRouteSpec>, action: SessionAction): Session<MyRouteSpec> {
  let out = state;
  switch (action.type) {
    case 'reset':
      if (!action.impersonate) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('mac_key');
        localStorage.removeItem('person');
      }
      out = {
        router: state.router,
      };
      break;
    case 'siteDown':
      out = {
        ...state,
        siteDown: !!action.siteDown,
      };
      break;
    case 'settings':
      out = {
        ...state,
        mfaEnabled: action.mfaEnabled || false,
        loginNotice: action.loginNotice || '',
        qrCodeResetWarning: action.qrCodeResetWarning || '',
      };
      break;
    case 'changeRoute':
      out = {
        ...state,
        router: action.router,
      };
      break;
    case 'personData':
      out = {
        router: state.router,
        person: new Document(action.person),
      };
      break;
    case 'sessionData':
      if (state.person) {
        const documents = new DocumentCollection(action.documents);
        out = {
          ...state,
          documents,
          person: documents.replace(action.person),
          forms: action.forms,
        };
      }
      break;
    case 'documentData':
      if (action.document && state.person) {
        if (state.documents) {
          out = {
            ...state,
            document: state.documents.replace(action.document),
          }
        } else {
          out = state;
          setTimeout(() => action.dispatch(action), 100);
        }
      } else {
        out = {
          ...state,
          document: undefined,
        }
      }
      break;
    default:
      throw new Error('invalid SessionAction');
  }
  console.warn('ACTION', action, '\nNEW STATE', out);
  return out;
}

export const SessionContext = createContext({
  session: {} as Session<MyRouteSpec>,
  dispatch: (action: SessionAction) => { },
});

export const App: React.FC = () => {
  const router = useRoutes(routes);
  const [session, dispatch] = useReducer(sessionReducer, { router });

  useEffect(() => {
    if (router.current.spec.title) {
      window.document.title = router.current.spec.title;
    }
    dispatch({
      router,
      type: 'changeRoute',
    });
  }, [router]);

  useEffect(() => {
   if (session.person && !session.documents) {
     getThicket(dispatch).then(async (result) => {
        if (!result.ok) {
          dispatch({ type: 'reset' });
        } else {
          const data = await result.json();
          dispatch({
            type: 'sessionData',
            person: data.person,
            documents: data.documents,
            forms: data.forms,
          });
        }
      });
    } else if (!session.person) {
      getSettings(dispatch).then(async (result) => {
        const data = await result.json();
        dispatch({
          type: 'settings',
          mfaEnabled: data.mfa.enabled || false,
          loginNotice: data.loginNotice.enabled ? data.loginNotice.notice || '' : '',
          qrCodeResetWarning: data.mfa.qrCodeResetWarning || '',
        });
      });
    }
  },        [session.person, session.documents]);

  if (!session.person && session.router.current.spec.private) {
    const person = localStorage.getItem('person');
    if (person) {
      dispatch({
        type: 'personData',
        person: JSON.parse(person),
      });
      return null;
    }
    return (
      <SessionContext.Provider value={{ session, dispatch }}>
        <PublicLayout title="Welcome to CHOP IRB Reliance Portal">
          <LoginForm />
        </PublicLayout>
      </SessionContext.Provider>
    );
  }

  return (
    <SessionContext.Provider value={{ session, dispatch }}>
      { router.current.spec.private ? (
        <Layout title={router.current.spec.title}>
          {router.current.result}
        </Layout>
      ) : (
        <PublicLayout title={router.current.spec.title}>
          { router.current.result }
        </PublicLayout>
      ) }
    </SessionContext.Provider>
  )
}