import React, { useState, useContext, useEffect } from 'react';
import { Alert, Button, Form, Icon, Input, Spin } from 'antd';
import { SessionContext } from './App';
import { postToken } from '../api/postToken';
import { postSendMFAToken } from '../api/postSendMFAToken';

export function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [totp, setTOTP] = useState('');
  const [emailTOTP, setEmailTOTP] = useState(false);
  const [totpEmailSent, setTOTPEmailSent] = useState(false);
  const [needUsername, setNeedUsername] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [serverError, setServerError] = useState(false);
  const { session, dispatch } = useContext(SessionContext);
  const [siteDown, setSiteDown] = useState(!!session.siteDown);
  const [loginNotice, setLoginNotice] = useState(session.loginNotice);
  const [mfaEnabled, setMFAEnabled] = useState(session.mfaEnabled);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'totp':
        setTOTP(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (session && session.person && session.router) {
      session.router.go('home');
    }
    setSiteDown(!!session.siteDown);
    setLoginNotice(session.loginNotice);
    setMFAEnabled(session.mfaEnabled);
  }, [session]);

  async function handleLogin(e: any) {
    e.preventDefault ? e.preventDefault() : e.returnValue = false;
    setSubmitted(true);
    setTOTPEmailSent(false);
    const result = await postToken(dispatch, { username, personName: "", password, totp: totp, emailTOTP: emailTOTP });
    setSubmitted(false);
    if (result.status === 400) {
      setServerError(false);
      setSiteDown(false);
      setLoginFailed(true);
    } else if (result.status === 503) {
      setLoginFailed(false);
      setServerError(false);
      setSiteDown(true);
    } else if (!result.ok) {
      setLoginFailed(false);
      setSiteDown(false);
      setServerError(true);
    } else {
      const body = await result.json();
      localStorage.setItem('mac_key', body.mac_key);
      localStorage.setItem('access_token', body.access_token);
      localStorage.setItem('person', JSON.stringify(body.person));
      dispatch({
        type: 'personData',
        person: body.person,
      });
    }
  }

  async function sendMFACode(e: any) {
    if (username) {
      setNeedUsername(false);
      setEmailTOTP(true);
      const result = await postSendMFAToken(dispatch, { username });
      if (result.status !== 202) {
        setServerError(true);
      }
      setTOTPEmailSent(true);
    } else {
      setNeedUsername(true);
    }
  }
  const goRecover = () => session.router.go('requestReset');

  return (
    <Spin tip="Logging in..." spinning={submitted} indicator={<Icon type="loading" />}>
      <Form
        className="login-form"
        onSubmit={handleLogin}
      >
        {loginFailed && (
          <Alert
            message="Login Failed"
            description={mfaEnabled ? "There was an error with your email, password or one-time password code. Failed to log in." : "There was an error with your email or password. Failed to log in."}
            type="error"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        {serverError && (
          <Alert
            message="Server Error"
            description="There was an error communicating with the server. Please try again soon."
            type="error"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        {siteDown && (
          <Alert
            message="Site down for maintenance"
            description="The CHOP IRB Reliance Portal is currently down for site maintenance. Please try again soon."
            type="error"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        {needUsername && (
          <Alert
            message="Username/email Required"
            description={"You must enter your username / email address to receive your One-Time Password Code via email."}
            type="error"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        {totpEmailSent && (
          <Alert
          message="One-Time Password Code"
          description="Your One-Time Password Code has been sent. It should appear in your inbox within a few minutes."
          type="success"
          style={{ marginBottom: '2em' }}
          showIcon={true}
        />
        )}
        {loginNotice && loginNotice !== '' && (
          <div className="ant-alert ant-alert-info" dangerouslySetInnerHTML={{__html: loginNotice}}></div>
        )}
        <p>
          Contact the Children’s Hospital of Philadelphia IRB with any questions at IRBoffice@email.chop.edu or 215-590-2830.
        </p>
        <Form.Item label="Email" colon={false}>
          <Input
            type="text"
            name="username"
            required={true}
            value={username}
            onChange={handleChange}
            prefix={<Icon type="mail" className="input-icon" />}
          />
        </Form.Item>

        <Form.Item label="Password" colon={false}>
          <Input.Password
            type="password"
            name="password"
            required={true}
            value={password}
            onChange={handleChange}
            prefix={<Icon type="lock" className="input-icon" />}
          />
        </Form.Item>

        {mfaEnabled === true && (
          <Form.Item label="One-Time Password Code" colon={false}>
              Enter your One-Time Password Code from MS Authenticator, or:
              <Button
              onClick={sendMFACode}
              >
              Send One-Time Password Code via Email
            </Button>
            <Input
              type="text"
              name="totp"
              required={true}
              value={totp}
              onChange={handleChange}
              prefix={<Icon type="lock" className="input-icon" />}
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: '1em' }}
          >
            Sign in
          </Button>
          <Button onClick={goRecover}>
            Recover Password or QR Code
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}