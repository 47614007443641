import { enc, HmacSHA256 } from 'crypto-js';

interface TokenAuthProps {
  uri: string;
  method: string;
  port?: number;
  mac_key: string | null;
  access_token: string | null;
}

const randChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';

const randStr = (length: number) => {
  const out = [];

  for (let i = 0; i < length; i += 1) {
    out.push(randChars.charAt(Math.floor(Math.random() * randChars.length)));
  }

  return out.join('');
};

export const tokenAuth = (props: TokenAuthProps) => {
  const ts = Math.floor(Date.now() / 1000);
  const port = props.port || 443;
  const nonce = randStr(18);
  const { hostname } = window.location;
  const str = `${ts}\n${nonce}\n${props.method || 'GET'}\n${props.uri}\n${hostname}\n${port}\n\n`;

  const mac = enc.Base64.stringify(
    HmacSHA256(str, props.mac_key),
  );

  return `MAC id="${props.access_token}", ts="${ts}", nonce="${nonce}", mac="${mac}"`;
};
