import React, { useContext, ChangeEvent } from "react";
import { FieldProps, Field, getFieldReadOnly } from "../Field";
import { Input } from "antd";
import { SessionContext } from "../../App";
import { StepContext } from "../Step";

interface TextFieldProps extends FieldProps {
  type?: string;

}

export const TextField = (props: TextFieldProps) => {
  const { session } = useContext(SessionContext);
  const { value, setValue } = useContext(StepContext);

  if (!session.document) {
    return null;
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!session.document) {
      return null;
    }

    setValue(e.target.value);
  }

  return (
    <Field {...props}>
      <Input
        type={props.type || "text"}
        readOnly={getFieldReadOnly(props, session.document)}
        name={props.name}
        defaultValue={value}
        onChange={onChange}
      />
    </Field>
  );
}