import React, { useContext, useState, useCallback } from "react";
import { SessionContext } from "./App";
import { List, Avatar, Divider, Spin, Icon, Menu, Dropdown, Button, Pagination } from "antd";
import { Document } from "../resources/Document";
import { Link } from "../hooks/useRoutes";
import { FORM_TYPES, FORM_TYPE_ICONS, FORM_TYPE_ICON_COLORS } from "../helpers/FORM_TYPES";
import { createRelated } from "../helpers/createRelated";
import { statusTag } from "../helpers/statusTag";
import { hasOpenAmendments, hasOpenReviews } from '../helpers/checkForOpenChildren';

export function Home() {
  const { session, dispatch } = useContext(SessionContext);
  const [ inboxPage, setInboxPage ] = useState(1);
  const [ inboxPageSize, setInboxPageSize ] = useState(5);
  const [ approvedPage, setApprovedPage ] = useState(1);
  const [ approvedPageSize, setApprovedPageSize ] = useState(5);

  const inboxPagintion = useCallback(() => {
    const start = (inboxPage-1)*inboxPageSize;
    return [start, start+inboxPageSize];
  }, [inboxPage, inboxPageSize])

  const approvedPagintion = useCallback(() => {
    const start = (approvedPage-1)*approvedPageSize;
    return [start, start+approvedPageSize];
  }, [approvedPage, approvedPageSize])

  if (!session || !session.documents) return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin indicator={<Icon type="loading"  style={{ fontSize: 48 }} spin={true}/>} />
    </div>
  );

  function setInboxPagination(current: number, pageSize?: number) {
    setInboxPage(current);
    if (pageSize) {
      setInboxPageSize(pageSize);
    }
  }

  function setApprovedPagination(current: number, pageSize?: number) {
    setApprovedPage(current);
    if (pageSize) {
      setApprovedPageSize(pageSize);
    }
  }

  function renderInboxItem(doc: Document) {
    if (!session) return null;
    return (
      <List.Item
        actions={[<Link href={session.router.getUrl('read', { id: doc.idOrSeq })}>{ doc.field('description') }</Link>]}
      >
        <List.Item.Meta
          title={<Link href={session.router.getUrl('read', { id: doc.idOrSeq })}>{ doc.name }</Link>}
          description={<>{FORM_TYPES[doc.type]} <span style={{ fontSize: '0.8em' }}>{statusTag(doc.state, doc)}</span></>}
          avatar={<Avatar
                    size={54}
                    icon={FORM_TYPE_ICONS[doc.type]}
                    style={{ backgroundColor: FORM_TYPE_ICON_COLORS[doc.type] }} />}
        />
      </List.Item>
    );
  }

  function renderItemActions(document: Document) {
    const amendments = document.rev('amends');
    const reviews = document.rev('review');

    const commands = [
      {
        title: 'Create Reportable Event',
        icon: FORM_TYPE_ICONS.revent,
        onClick: () => createRelated({
          dispatch,
          session,
          document,
          child: {
            type: "revent",
            rel: "happenedOn",
            copy: false,
            prefix: "Reportable Event",
            fields: {
              state: "Open",
              editable: true,
            },
          },
        }),
      },
      {
        title: 'Create Continuing Review',
        icon: FORM_TYPE_ICONS.cr,
        disabled: !!hasOpenReviews(reviews),
        onClick: () => createRelated({
          dispatch,
          session,
          document,
          child: {
            type: "cr",
            rel: "review",
            copy: false,
            prefix: "Continuing Review",
            fields: {
              state: "Open",
              editable: true,
            },
          },
        }),
      },
      {
        title: 'Create Amendment',
        icon: FORM_TYPE_ICONS.amendment,
        disabled: !!hasOpenAmendments(amendments),
        onClick: () => createRelated({
          dispatch,
          session,
          document,
          child: {
            type: "study",
            rel: "amends",
            copy: true,
            prefix: "Amendment",
            fields: {
              state: "Open",
              editable: true,
              description: "Edit Amendment to make necessary changes.",
              isAmendment: true
            },
          },
        }),
      },
    ];
    const clickHandlers: { [ index: string ]: Function } = {};
    const handleMenuClick = (e: any) => {
      clickHandlers[e.key](e);
    };
    const items = commands.map((item: any) => {
      clickHandlers[item.title] = item.onClick;
      return (
        <Menu.Item
          key={item.title}
          disabled={item.disabled}
        >
          <Icon type={item.icon} /> {item.title}</Menu.Item>
      );
    });
    return (
      <Dropdown key={'dropdown'} overlay={<Menu onClick={handleMenuClick}>{items}</Menu>} trigger={['click']}>
        <Button icon="plus" />
      </Dropdown>
    );
  }

  function renderItem(doc: Document) {
    if (!session) return null;
    return (
      <List.Item
        actions={[renderItemActions(doc)]}
      >
        <List.Item.Meta
          title={<Link href={session.router.getUrl('read', { id: doc.idOrSeq })}>{ doc.name }</Link>}
          description={FORM_TYPES[doc.type]}
          avatar={<Avatar
                    size={54}
                    icon={FORM_TYPE_ICONS[doc.type]}
                    style={{ backgroundColor: FORM_TYPE_ICON_COLORS[doc.type] }} />}
        />
      </List.Item>
    );
  }

  const inbox = session.documents.inbox;
  const approved = session.documents.approvedStudies;

  return (
    <div className="main" style={{ maxWidth: '72em', margin: '0 auto' }}>
      <Pagination
        style={{ float: 'right' }}
        size="small"
        showSizeChanger
        onChange={setInboxPagination}
        onShowSizeChange={setInboxPagination}
        pageSize={inboxPageSize}
        pageSizeOptions={['5', '10', '20', '50', '100']}
        current={inboxPage}
        total={inbox.length}
      />
      <h3>Inbox</h3>
      <List
        itemLayout="horizontal"
        dataSource={inbox.slice(...inboxPagintion())}
        renderItem={renderInboxItem}
      />
      <Divider />
      <Pagination
        style={{ float: 'right' }}
        size="small"
        showSizeChanger
        onChange={setApprovedPagination}
        onShowSizeChange={setApprovedPagination}
        pageSize={approvedPageSize}
        pageSizeOptions={['5', '10', '20', '50', '100']}
        current={approvedPage}
        total={approved.length}
      />
      <h3>Approved Studies</h3>
      <List
        itemLayout="horizontal"
        dataSource={approved.slice(...approvedPagintion())}
        renderItem={renderItem}
      />
    </div>
  )
}
