import React, { useContext, useState, ReactElement, useEffect } from 'react';
import { PageHeader, Icon, Descriptions, List, Avatar, Spin, message, Result, Button, Alert } from "antd"
import { FORM_TYPES, FORM_TYPE_ICONS, FORM_TYPE_ICON_COLORS } from "../helpers/FORM_TYPES";
import { Document } from "../resources/Document";
import { SessionContext } from './App';
import { CommandButtons } from './CommandButtons';
import { TabMenu } from './TabMenu';
import { Link, Redirect } from '../hooks/useRoutes';
import { dateTimeRenderer } from './SubmissionTable/helpers';
import { studyWizard } from '../wizards/studyWizard';
import { getDocument } from '../api/getDocument';
import { statusTag } from '../helpers/statusTag';
import { genericTag } from '../helpers/genericTag';
import { WizardStep, Wizard } from './Wizard/Wizard';
import { amendmendWizard } from '../wizards/amendmentWizard';
import { piWizard } from '../wizards/piWizard';
import { crWizard } from '../wizards/crWizard';
import { reventWizard } from '../wizards/reventWizard';
import { createRelated } from '../helpers/createRelated';
import { scWizard } from '../wizards/scWizard';
import { hasOpenAmendments, hasOpenReviews } from '../helpers/checkForOpenChildren';

interface DocumentPageProps {
  id: string;
  tab?: string;
  page?: string;
}

const FORMS: { [index: string]: WizardStep[] } = {
  study: studyWizard,
  amendment: amendmendWizard,
  pi: piWizard,
  sc: scWizard,
  cr: crWizard,
  revent: reventWizard,
};

export const Workflow = ({ id, tab }: DocumentPageProps) => {
  const { session, dispatch } = useContext(SessionContext);
  const { document } = session;

  useEffect(() => {
    return () => dispatch({ dispatch, type: 'documentData', document: undefined });
  }, [dispatch]);

  const [routeState, setRouteState] = useState({
    id: session.router.current.params.id,
    router: session.router,
  });

  useEffect(() => {
    if (routeState.id !== session.router.current.params.id) {
      setRouteState({
        id: session.router.current.params.id,
        router: session.router,
      });
    }
  }, [session.router, routeState.id]);

  useEffect(() => {
    const { id, router } = routeState;
    if (session.person && id) {
      getDocument(dispatch, id).then(async (result) => {
        if (result.status === 404) {
          router.go('home');
          setImmediate(() => message.error('Document not found.'));

        } else if (!result.ok) {
          router.go('home');
          setImmediate(() => message.error('Permission denied.'));
        } else {
          const data = await result.json();
          dispatch({
            dispatch,
            type: 'documentData',
            document: data
          });
        }
      });
    }
  }, [session.person, dispatch, routeState]);

  useEffect(() => {
    if (document && document.type === 'study' && document.pi && document.pi.unsubmitted) {
      session.router.go('read', { id: document.pi.idOrSeq });
      message.warning(`You must submit information about the Principal Investigator (${document.pi.name}) before submitting this Study.`);
    }
  }, [document]);

  if (!document || (document.type === 'study' && !document.collection)) return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin indicator={<Icon type="loading" style={{ fontSize: 48 }} spin={true} />} />
    </div>
  );

  let description: ReactElement | undefined = undefined;

  const tags = document.state ? [statusTag(document.state, document)] : [];
  if (document.chopCompleteRelyingApplication) {
    tags.push(genericTag('CHOP Study Team Will Complete This Application', 'green'));
  }    

  if (document.type === 'study') {
    description = (
      <Descriptions bordered={true} size="small">
        <Descriptions.Item label="CHOP Study Title">{document.field('chop_studyTitle')}</Descriptions.Item>
        <Descriptions.Item label="CHOP Short Name">{document.field('chop_studyShortName')}</Descriptions.Item>
        <Descriptions.Item label="CHOP Exp. Date">{document.field('chop_studyExpirationDate') || <em>N/A</em>}</Descriptions.Item>
        <Descriptions.Item label="CHOP PI Name">{document.field('chop_studyPIFirstName')} {document.field('chop_studyPILastName')}</Descriptions.Item>
        <Descriptions.Item label="CHOP IRB Phone">(215) 590-2830</Descriptions.Item>
        <Descriptions.Item label="CHOP IRB Email">IRBoffice@email.chop.edu</Descriptions.Item>
      </Descriptions>
    );
  } else if (document.type === 'pi') {
    description = (
      <Descriptions bordered={true} size="small">
        <Descriptions.Item label="PI Name">{document.field('name')}</Descriptions.Item>
        <Descriptions.Item label="PI Email">{document.field('email')}</Descriptions.Item>
        <Descriptions.Item label="CHOP IRB Phone">(215) 590-2830</Descriptions.Item>
        <Descriptions.Item label="CHOP IRB Email">IRBoffice@email.chop.edu</Descriptions.Item>
      </Descriptions>
    );
  }

  const history = document.rel('history').sort((d1, d2) => {
    const a = d1.created || 0;
    const b = d2.created || 0;
    if (a > b) {
      return -1;
    } else if (b > a) {
      return 1;
    }
    return 0;
  });

  const amendments = document.rev('amends');
  const revents = document.rev('happenedOn');
  const reviews = document.rev('review');

  const commands: any[] = [];

  const steps = FORMS[`${document.type}`];

  if (steps && session.router.current.name === 'read' &&
    !(document.type === 'study' && document.pi && document.pi.unsubmitted)) {
    commands.push({
      title: document.editable ? 'Edit Application' : 'Review Application',
      icon: document.editable ? 'edit' : 'eye',
      onClick: () => session.router.go('form', { id, page: steps[0].name }),
    });
  }

  commands.push({
    title: 'Back to Home',
    icon: 'home',
    onClick: () => session.router.go('home'),
  });

  if (document.type === 'study' &&
    (document.state === 'Approved' || document.state === 'Exemption Granted')) {
    commands.push({
      title: 'Create Reportable Event',
      icon: FORM_TYPE_ICONS.revent,
      onClick: () => createRelated({
        dispatch,
        session,
        document,
        child: {
          type: "revent",
          rel: "happenedOn",
          copy: false,
          prefix: "Reportable Event",
          fields: {
            state: "Open",
            editable: true,
          },
        },
      }),
    });

    if (!hasOpenReviews(reviews)) {
      commands.push({
        title: 'Create Continuing Review',
        icon: FORM_TYPE_ICONS.cr,
        onClick: () => createRelated({
          dispatch,
          session,
          document,
          child: {
            type: "cr",
            rel: "review",
            copy: false,
            prefix: "Continuing Review",
            fields: {
              state: "Open",
              editable: true,
            },
          },
        }),
      });
    }

    if (!hasOpenAmendments(amendments)) {
      commands.push({
        title: 'Create Amendment',
        icon: FORM_TYPE_ICONS.amendment,
        onClick: () => createRelated({
          dispatch,
          session,
          document,
          child: {
            type: "study",
            rel: "amends",
            copy: true,
            prefix: "Amendment",
            fields: {
              state: "Open",
              editable: true,
              description: "Edit Amendment to make necessary changes.",
              isAmendment: true
            },
          },
        }),
      });
    }
  }

  function renderDocument(doc: Document) {
    const goTo = () => {
      session.router.go('read', { id: doc.idOrSeq });
    };
    return (
      <List.Item onClick={goTo}>
        <List.Item.Meta
          title={<Link href={session.router.getUrl('read', { id: doc.idOrSeq })}>{doc.name}</Link>}
          description={FORM_TYPES[doc.type]}
          avatar={<Avatar
            size={54}
            icon={FORM_TYPE_ICONS[doc.type]}
            style={{ backgroundColor: FORM_TYPE_ICON_COLORS[doc.type] }} />}
        />
      </List.Item>
    );
  }

  function renderHistoryDocument(doc: any) {
    return (
      <List.Item>
        <Icon style={{ marginRight: '0.5em' }} type="file" /> <a target="_blank" rel="noopener noreferrer" href={doc.uri}>{doc.name}</a>
      </List.Item>
    )
  }

  function renderHistory(doc: Document) {
    return (
      <List.Item>
        <List.Item.Meta
          title={doc.name}
          description={(
            <>
              <p>{dateTimeRenderer(doc.field('timestamp'))}</p>
              <p>{doc.field('description')}</p>
              {doc.documents('documents').length > 0 && (
                <List
                  dataSource={doc.documents('documents')}
                  bordered={true}
                  renderItem={renderHistoryDocument} />
              )}
            </>
          )}
        />
      </List.Item>
    );
  }
  const tabs = [];

  let currentTab = tab;
  let historyDefault = false;

  if (history.length > 0) {
    historyDefault = true;
    tabs.push({
      name: 'History',
      default: true,
      key: 'history',
      component: <List
        itemLayout="horizontal"
        dataSource={history}
        renderItem={renderHistory}
      />,
    });
    if (!currentTab) {
      currentTab = "history";
    }
  }

  if (steps) {
    tabs.push({
      name: (document.editable ? 'Edit' : 'Review') + ' Application',
      key: 'form',
      default: !historyDefault,
      component: <Wizard steps={steps} />,
    });
    if (!currentTab) {
      currentTab = "form";
    }
  }

  if (history.length === 0) {
    tabs.push({
      name: 'History',
      key: 'history',
      component: <List
        itemLayout="horizontal"
        dataSource={history}
        renderItem={renderHistory}
      />,
    });
    if (!currentTab) {
      currentTab = "history";
    }
  }

  if (amendments.length) {
    tabs.push({
      name: 'Amendments',
      key: 'amendments',
      component: <List
        itemLayout="horizontal"
        dataSource={amendments}
        renderItem={renderDocument}
      />,
    });
  }

  if (revents.length) {
    tabs.push({
      name: 'Reportable Events',
      key: 'reportable',
      component: <List
        itemLayout="horizontal"
        dataSource={revents}
        renderItem={renderDocument}
      />,
    });
  }

  if (reviews.length) {
    tabs.push({
      name: 'Continuing Reviews',
      key: 'reviews',
      component: <List
        itemLayout="horizontal"
        dataSource={reviews}
        renderItem={renderDocument}
      />,
    });
  }

  if (!currentTab) {
    currentTab = "empty";
  }

  const goPi = () => document.pi && session.router.go('read', { id: document.pi.idOrSeq });

  return (
    <>
      <PageHeader
        title={document.name}
        tags={tags}
        subTitle={null}
        extra={<CommandButtons commands={commands} />}
        avatar={{
          icon: FORM_TYPE_ICONS[document.type],
          style: { backgroundColor: FORM_TYPE_ICON_COLORS[document.type] }
        }}
      >
        {document.field('changesRequested') && (
          <Alert
            style={{ marginBottom: '1em' }}
            message="Changes Required"
            description={<div dangerouslySetInnerHTML={{ __html: document.field('changesRequested') }} />}
            type="warning"
            showIcon
          />
        )}
        {description}
      </PageHeader>
      <div className="main">
        {document.type === 'study' && document.pi && document.pi.unsubmitted ? (
          <Result
            title={`You must submit information about the Principal Investigator (${document.pi.name}) before submitting this Study.`}
            extra={
              <Button type="primary" key="pi" onClick={goPi}>Edit {document.pi.name}</Button>
            } />
        ) : (
            <TabMenu tab={currentTab} tabs={tabs} location="tab" defaultLocation="read" urlParams={{ id }} />
          )}
      </div>
    </>
  );
};