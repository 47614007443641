import * as React from 'react';
import { Button, Menu, Icon, Dropdown } from 'antd';

export type ClickHandler = React.EventHandler<React.MouseEvent<HTMLButtonElement>>;

export interface Command {
  title: string;
  icon: string;
  enabled?: boolean;
  onClick: ClickHandler;
}

interface Props {
  commands: Command[];
}

export function CommandButtons({ commands }: Props) {
  const [count, setCount] = React.useState(commands.length);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [oldWidth, setOldWidth] = React.useState(width);

  function handleResize() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const updateCount = (el: HTMLDivElement) => {
    if (el) {
      const heading = document.querySelector('.ant-page-header > .ant-page-header-heading');
      if (heading && heading.clientHeight > 50 && count > 0) {
        setCount(count - 1);
      }
    }
  };

  React.useEffect(
    () => {
      if (width > oldWidth) {
        setCount(commands.length);
      }
      setOldWidth(width);
    },
    [width, oldWidth, commands.length],
  );

  const out = [];

  commands.slice(0, count).forEach((item: Command, i: number) => {
    out.push((
      <Button
        key={item.title}
        type="primary"
        disabled={item.enabled === false}
        onClick={item.onClick}
        icon={item.enabled === false ? 'loading' : item.icon}
      >
        {item.title}
      </Button>
    ));
  });

  const rest = commands.slice(count);

  if (rest.length > 0) {
    const clickHandlers: { [ index: string ]: Function } = {};
    const handleMenuClick = (e: any) => {
      clickHandlers[e.key](e);
    };
    const items = rest.map((item: Command, i: number) => {
      clickHandlers[item.title] = item.onClick;
      return (
        <Menu.Item key={item.title} disabled={item.enabled === false}><Icon type={item.enabled === false ? 'loading' : item.icon} /> {item.title}</Menu.Item>
      );
    });
    out.push((
      <Dropdown key={'dropdown'} overlay={<Menu onClick={handleMenuClick}>{items}</Menu>} trigger={['click']}>
        <Button
          type="primary"
          icon={count === 0 ? 'setting' : 'ellipsis'}
        />
      </Dropdown>
    ));

  }

  return <div ref={updateCount}>{out}</div>;
}
