import React, { useContext } from "react";
import { SessionContext } from "./App";
import { SubmissionTable } from "./SubmissionTable/SubmissionTable";
import { TabMenu } from "./TabMenu";

interface AllProps {
  tab: string;
}

export function All ({ tab }: AllProps) {
  const { session } = useContext(SessionContext);

  if (!session.documents) {
    return null;
  }

  const tabs = [
    {
      name: 'All',
      key: 'all',
      default: true,
      component: <SubmissionTable key="all" dataSource={session.documents.allData} filterCategory={true} />,
    },
    {
      name: 'By Study',
      key: 'study',
      component: <SubmissionTable key="study" dataSource={session.documents.nestedData} />,
    },
  ]

  return (
    <div className="main">
      <TabMenu tab={tab} tabs={tabs} defaultLocation="all" location="allTab" />
    </div>
  )

}