import { Dispatch } from 'react';
import { request } from './request';
import { SessionAction } from '../interfaces/Session';

interface GetResetTokenProps {
  token: string,
}

export function getResetToken(dispatch: Dispatch<SessionAction>, props: GetResetTokenProps) {
  return request(dispatch, '/auth/invite/' + props.token, {
    auth: false,
  });
}