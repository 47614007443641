import { Dispatch } from 'react';
import { request } from './request';
import { SessionAction } from '../interfaces/Session';

export function postImpersonate(dispatch: Dispatch<SessionAction>, id: string | null) {
  return request(dispatch, '/auth/impersonate', {
    method: 'POST',
    body: {
      id,
    },
  });
}