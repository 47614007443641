import { Dispatch } from 'react';
import { tokenAuth } from "../helpers/tokenAuth";
import { SessionAction } from '../interfaces/Session';

interface RequestParams {
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE';
  body?: any;
  auth?: boolean;
}

export async function request(dispatch: Dispatch<SessionAction>, uri: string, { method = 'GET', body, auth }: RequestParams = {}) {
  const headers: any = auth !== false ? {
    Authorization: tokenAuth({
      uri,
      method,
      mac_key: localStorage.getItem('mac_key'),
      access_token: localStorage.getItem('access_token'),
    }),
  } : {};

  if (body && !(body instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
  }

  const options: any = {
    method,
    headers,
  };

  if (body && !(body instanceof FormData)) {
    options.body = JSON.stringify(body);
  } else if (body) {
    options.body = body;
  }

  const result = await fetch(uri, options);

  if (result.headers.get('X-Site-Down') === 'true') {
    dispatch({
      type: 'siteDown',
      siteDown: true,
    });
  } else {
    dispatch({
      type: 'siteDown',
      siteDown: false,
    });
  }

  return result;
}