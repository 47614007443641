import React, { ReactNode, useContext } from "react";
import { Header } from "./Header";
import { SessionContext } from './App';

interface LayoutProps {
  title?: string;
  children?: ReactNode | ReactNode[];
}

export function Layout(props: LayoutProps) {
  const { session } = useContext(SessionContext);

  return (
    <>
      { session.siteDown && (
        <header className="site-down">The internal CHOP IRB site is currently offline. Form processing may be delayed.</header>
      ) }
      <Header />
      {props.children}
    </>
  );
}