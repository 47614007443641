import React, { useState, FormEvent, useContext, useEffect } from 'react';
import { Alert, Button, Form, Icon, Input, Spin } from 'antd';
import { QRCode } from 'react-qrcode-logo';
import { SessionContext } from './App';
import { getMFAToken } from '../api/getMFAToken';
import { getMFAUserInfo } from '../api/getMFAUserInfo';
import { postPasswordChange } from '../api/postPasswordChange';

interface MFAQRCodeProps {
  token: string;
}
export const MFAQRCode = ({ token }: MFAQRCodeProps) => {
  const [loading, setLoading] = useState(true);
  const [expiredToken, setExpiredToken] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [totpURI, setTOTPURI] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const [resetWarning, setResetWarning] = useState('');
  const [needsPassword, setNeedsPassword] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [username, setUsername] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [weakPassword, setWeakPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [resetFailed, setResetFailed] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [passwordSet, setPasswordSet] = useState(false);
  const { session, dispatch } = useContext(SessionContext);
  
  useEffect(() => {
    setLoading(true);
    getMFAUserInfo(dispatch, { token }).then(response => {
      if (response.status === 404) {
        setExpiredToken(true);
      }
      return response.json();
    }).then(userInfo => {
      setUsername(userInfo.email);
      setNeedsPassword(userInfo.needsPassword || false);
      setExpiredToken(userInfo.qrCodeURIUsed || false);
    });
    setResetWarning(session.qrCodeResetWarning || 'If you reset your QR code, your current authentication setup will expire. Are you sure you want to do this?');
    setLoading(false);
  }, []);

  const generateToken = () => {
    setConfirmed(true);
    setGenerating(true);
    getMFAToken(dispatch, { token }).then(response => {
      if (response.status === 404) {
        setExpiredToken(true);
      }
      return response.json();
    }).then(mfaToken => {
      if (mfaToken.used) {
        setExpiredToken(true);
      } else {
        setExpiredToken(false);
        const otpURI = `otpauth://totp/CHOP IRB Reliance Portal:${mfaToken.email}?secret=${mfaToken.secret.replace(/\W/g, '').toLowerCase()}&issuer=CHOP`;
        setTOTPURI(encodeURI(otpURI));
      }
      setGenerating(false);
    });
  };

  const handlePasswordChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'password1':
        setPassword1(value);
        break;
      case 'password2':
        setPassword2(value);
        break;
      default:
        break;
    }
  };

  async function handlePassword(e: FormEvent) {
    e.preventDefault();

    if (password1 !== password2) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
      setWeakPassword(false);
      setSubmitted(true);
      const result = await postPasswordChange(dispatch, { username, personName: '', password: password1, token });
      setSubmitted(false);
      if (result.status === 400) {
        result.json().then(data => {
          if (data.type === 'password') {
            setWeakPassword(true);
            setResetFailed(false);
          } else {
            setWeakPassword(false);
            setResetFailed(true);
          }
        })
        setServerError(false);
        setPasswordSet(false);
      } else if (!result.ok) {
        setResetFailed(false);
        setServerError(true);
        setPasswordSet(false);
      } else {
        setResetFailed(false);
        setServerError(false);
        setPasswordSet(true);
        setNeedsPassword(false);
      }
    }
  }
  if (loading) {
    return (
      <Spin tip="Loading..." spinning={loading} indicator={<Icon type="loading" />}></Spin>
    );
  } else if (expiredToken) {
    return (
      <Alert
        message="URL Expired"
        description="This QR code URL has expired."
        type="error"
        style={{ marginBottom: '2em' }}
        showIcon={true}
      />
    );
  } else if (!needsPassword && !confirmed && resetWarning) {
    return (
      <div>
        {passwordSet && (
          <Alert
            message="New Password Set"
            description="Your password has been set"
            type="success"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        <div className="ant-alert ant-alert-info" dangerouslySetInnerHTML={{__html: resetWarning}}></div>
        <Form.Item>
          <Button onClick={generateToken}
            style={{ marginRight: '1em' }}
          >
            Yes, reset my QR Code
          </Button>
        </Form.Item>
      </div>
    );
  } else if (needsPassword) {
    return (
    <div>
      <Spin tip="Setting new password..." spinning={submitted} indicator={<Icon type="loading" />}>
        <Form
          className="reset-form"
          onSubmit={handlePassword}
        >
          <Alert
            message="Set Password"
            description="Before generating a QR Code, you must finish creating your account by setting a password"
            type="info"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
          {resetFailed && (
            <Alert
              message="Setting password Failed"
              description="There was an error with setting your new passwowrd."
              type="error"
              style={{ marginBottom: '2em' }}
              showIcon={true}
            />
          )}
          {serverError && (
            <Alert
              message="Server Error"
              description="There was an error communicating with the server. Please try again soon."
              type="error"
              style={{ marginBottom: '2em' }}
              showIcon={true}
            />
          )}
          {passwordError && (
            <Alert
              message="Invalid Password"
              description="The two passwords do not match"
              type="error"
              style={{ marginBottom: '2em' }}
              showIcon={true}
            />
          )}
          {weakPassword && (
            <Alert
              message="Weak Password"
              description="Password cannot contain your name and requires at least 3 of the following: uppercase, lowercase, numbers, or special characters"
              type="error"
              style={{ marginBottom: '2em' }}
              showIcon={true}
            />
          )}
          <p>Please enter in your new password below</p>
          <Form.Item label="Enter new Password" colon={false} extra="">
            <Input.Password
              type="password"
              name="password1"
              required={true}
              value={password1}
              onChange={handlePasswordChange}
              prefix={<Icon type="lock" className="input-icon" />}
            />
          </Form.Item>

          <Form.Item label="Retype Password" colon={false}>
            <Input.Password
              type="password"
              name="password2"
              required={true}
              value={password2}
              onChange={handlePasswordChange}
              prefix={<Icon type="lock" className="input-icon" />}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: '1em' }}
            >
              Set Password
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>);
  } else {
    return (
      <Spin tip="Generating new QR code..." spinning={generating} indicator={<Icon type="loading" />}>
        {!generating && !expiredToken && (
          <div>
            <p>Scan the QR code below using Microsoft Authenticator</p>
            <p>This is a one-time use QR code, so if you return to this URI later, it will not be displayed</p>
            <QRCode value={totpURI} />
          </div>
        )}
      </Spin>
    );
  }
}