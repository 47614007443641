import { Dispatch } from 'react';
import { request } from "./request";
import { SessionAction } from '../interfaces/Session';

export function postDocument(dispatch: Dispatch<SessionAction>, id: string, fields: any) {
  return request(dispatch, `/thicket/${id}`, {
    method: 'POST',
    body: {
      fields,
    },
  });
}