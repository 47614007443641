import { DocumentSpec } from "../interfaces/DocumentSpec";
import { DocumentCollection } from "../collections/DocumentCollection";
import { DocumentReference } from "../types/Document";

export class Document {
  private spec: DocumentSpec;
  readonly collection?: DocumentCollection;
  readonly created?: Date;

  constructor(spec: DocumentSpec, collection?: DocumentCollection) {
    this.spec = spec;
    this.collection = collection;
    this.created = spec.created ? new Date(spec.created) : undefined;
  }

  get realSeq() {
    return this.spec.realSeq;
  }

  get type() {
    return this.spec.fields['isAmendment'] ? 'amendment' : this.spec.type;
  }

  get piChoices() {
    return this.spec.piChoices || [];
  }

  get scChoices() {
    return this.spec.scChoices || [];
  }

  get state() {
    switch (this.spec.fields.state) {
      case 'Draft':
      case 'Open':
      case 'Profile':
        return 'Pre-Submission';
      case 'Review in Progress':
      case 'Pre-Review':
        return 'In Review';
      case 'Changes Requested':
      case 'Modifications Required':
        return 'Action Required';
      case 'Approved':
      case 'Submitted':
      case 'Exempted':
        return 'Approved';
      default:
        return this.spec.fields.state;
    }
  }

  get idOrSeq() {
    if (this.spec.id) {
      return this.id;
    }
    return this.seq;
  }

  get id() {
    return `${this.spec.id}`;
  }

  get seq() {
    return `${this.spec.seq || 0}`;
  }

  get name() {
    if (this.seq === "0") {
      return "System Administrator";
    } else {
      return this.spec.fields.name || ((this.spec.fields.firstName || "System") + " " + (this.spec.fields.lastName || "Administrator"));
    }
  };

  get editable(): boolean {
    return !!this.spec.fields.editable && !this.spec.fields.chopCompleteRelyingApplication && (!this.pi || !this.pi.unsubmitted);
  }

  get chopCompleteRelyingApplication(): boolean {
    return !!this.spec.fields.chopCompleteRelyingApplication;
  }

  field(field: string, value?: any) {
    if (field === "name") {
      return this.name;
    }
    if (field === "id") {
      return this.id;
    }
    if (field === "type") {
      return this.type;
    }
    if (value !== undefined && this.editable) {
      var fields: any = {};
      fields[field] = value;
      this.spec.fields[field] = value;
    } else {
      return this.spec.fields[field];
    }
  };

  get email() {
    return this.spec.fields.email;
  }

  documents(key: string) {
    if (this.spec.documents) {
      return this.spec.documents[key] || [];
    }
    return [];
  }

  get canAdmin() {
    return !!this.spec.admin;
  }

  get isAdmin() {
    return !!(this.spec.fields && this.spec.fields.isAdmin);
  }

  is(type: string) {
    return this.spec.type === type;
  }

  rel(predicate: string, value?: DocumentReference[]): Document[] {
    if (value !== undefined && this.editable) {
      this.spec.rel[predicate] = value;
    }

    if (this.collection && this.spec.rel[predicate]) {
      const docs = this.spec.rel[predicate].map(dr => this.collection ? this.collection.get(dr) : undefined);
      return docs.filter(d => d) as Document[];
    }
    return [];
  }

  rev(predicate: string): Document[] {
    if (this.collection && this.spec.rev[predicate]) {
      const docs = this.spec.rev[predicate].map(dr => this.collection ? this.collection.get(dr) : undefined);
      return docs.filter(d => d) as Document[]
    }
    return [];
  }

  get children() {
    return ([] as Document[]).concat(
      this.rev('amends')[0],
      this.rev('review')[0],
      this.rev('happenedOn')[0],
    ).filter(d => !!d);
  }

  get pi() {
    if (this.type === 'study') {
      return this.rel('pi')[0] || (this.collection && this.collection.pi);
    }
    return null;
  }

  get unsubmitted() {
    return !this.field('hasBeenSubmittedOnce') && this.field("editable");
  }

  get impersonating() {
    return this.spec.realSeq != null && this.spec.seq !== this.spec.realSeq;
  }
}

export type DocumentPredicate = (doc: Document) => boolean;