import React, { useState, FormEvent, useContext, useEffect } from 'react';
import { Alert, Button, Form, Icon, Input, Radio, Spin } from 'antd';
import { RadioChangeEvent } from "antd/lib/radio";
import { SessionContext } from './App';
import { postReset } from '../api/postReset';
import { emailRegex } from '../helpers/regex';

function ResetForm(props: any) {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [resetFailed, setResetFailed] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const [resetType, setResetType] = useState('');
  const [noResetTypeError, setNoResetTypeError] = useState(false);
  const { session, dispatch } = useContext(SessionContext);

  const handleEmailChange = (e: any) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleResetTypeChange = (e: RadioChangeEvent) => {
    setResetType(e.target.value);
  };

  useEffect(() => {
    if (session && session.person && session.router) {
      session.router.go('home');
    }
  }, [session]);

  function handleReset(e: FormEvent) {
    e.preventDefault();
    const isValidEmail = emailRegex.test(email);
    if (!resetType) {
      setNoResetTypeError(true);
      setServerError(false);
      setResetFailed(false);
      setResetSent(false);
    } else if (!isValidEmail) {
      setNoResetTypeError(false);
      setInvalidEmailError(true);
      setServerError(false);
      setResetFailed(false);
      setResetSent(false);
    } else {
      setNoResetTypeError(false);
      setInvalidEmailError(false);
      setSubmitted(true);
      postReset(dispatch, { username: email, resetType: resetType }).then(result => {
        setSubmitted(false);
        if (result.status === 400) {
          setServerError(false);
          setResetFailed(true);
          setResetSent(false);
        } else if (!result.ok) {
          setResetFailed(false);
          setServerError(true);
          setResetSent(false);
        } else {
          // reset sent
          setServerError(false);
          setResetFailed(false);
          setResetSent(true);
        }
      });
    }
  }

  const goBack = () => session.router.go('home');

  return (
    <Spin tip="Sending reset..." spinning={submitted} indicator={<Icon type="loading" />}>
      <Form
        className="reset-form"
        onSubmit={handleReset}
      >
        {resetFailed && (
          <Alert
            message="Reset Failed"
            description="There was an error with your email. Failed to reset."
            type="error"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        {serverError && (
          <Alert
            message="Server Error"
            description="There was an error communicating with the server. Please try again soon."
            type="error"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        {invalidEmailError && (
          <Alert
            message="Invalid Email"
            description="Please enter a valid email address"
            type="error"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        {noResetTypeError && (
          <Alert
            message="No Option Chosen"
            description="Please choose either Recover Password or Resend MS Authenticator QR Code"
            type="error"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        {resetSent && (
          <Alert
            message="Message Sent"
            description="Expect an email within 3-5 minutes if there is an account with that email address."
            type="success"
            style={{ marginBottom: '2em' }}
            showIcon={true}
          />
        )}
        <p>Please choose an option and enter in your email address below to:</p>
        <Radio.Group onChange={handleResetTypeChange} value={resetType}>
          <Radio value={'password'}>Recover Password</Radio>
          <Radio value={'qrCode'}>Resend MS Authenticator QR Code</Radio>
        </Radio.Group>
        <Form.Item label="Email" colon={false}>
          <Input
            type="text"
            name="username"
            required={true}
            value={email}
            onChange={handleEmailChange}
            prefix={<Icon type="mail" className="input-icon" />}
          />
        </Form.Item>
        <Form.Item>
          <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: '1em' }}
          >
            Submit
          </Button>
          <Button onClick={goBack}>
            Go Back
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default Form.create()(ResetForm);