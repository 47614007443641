import { Document } from "../resources/Document";
import { DocumentSpec } from "../interfaces/DocumentSpec";
import { DocumentMap, DocumentSpecMap, DocumentReference } from "../types/Document";
import { FORM_TYPES } from "../helpers/FORM_TYPES";

const INBOX_TYPES = new Set([
  'cr',
  'study',
  'revent',
  'amendment',
]);

const INBOX_STATES = new Set([
  'Pre-Submission',
  'Action Required',
  'Submission Failed',
]);

const formatDoc = (d: Document) => ({
  key: d.idOrSeq,
  title: d.name || '',
  type: FORM_TYPES[d.type] || '',
  state: d.state || '',
  date: d.created ? d.created.getTime() : '',
  action: d.field('description'),
  editable: d.editable,
});

const formatNestedDoc = (d: Document) => ({
  key: d.idOrSeq,
  title: d.name || '',
  type: FORM_TYPES[d.type] || '',
  state: d.state || '',
  date: d.created ? d.created.getTime() : '',
  action: d.field('description'),
  editable: d.editable,
  children: d.children.length > 0 ? d.children.map(formatDoc) : undefined,
});

export class DocumentCollection {
  private byEmail: DocumentMap;
  private bySeq: DocumentMap;
  private byId: DocumentMap;

  constructor(documents: DocumentSpecMap) {
    this.byEmail = {};
    this.bySeq = {};
    this.byId = {};

    for (const id in documents) {
      if (documents[id].parent !== undefined) {
        this.replace(documents[id]);
      }
    }
  }

  get(key: string | DocumentReference): Document {
    let index: string;
    if (typeof key === 'string') {
      index = key;
    } else {
      index = key.id || `${key.seq}`;
    }
    return this.bySeq[index] || this.byId[index] || this.byEmail[index];
  }

  replace(spec: DocumentSpec) {
    const doc = new Document(spec, this);
    this.byId[doc.id] = doc;
    this.bySeq[doc.seq] = doc;
    if (doc.email) {
      this.byEmail[doc.email] = doc;
    }
    return doc;
  }

  get all() {
    return Object.values(this.bySeq);
  }

  get allData() {
    return this.all.filter(d => d.type !== 'history' && d.type !== 'contact' && d.type !== 'institution').map(formatDoc);
  }

  get nestedData() {
    return this.all.filter(d => d.type === 'study').map(formatNestedDoc);
  }

  get inbox() {
    return this.all.filter(d => INBOX_TYPES.has(d.type) && INBOX_STATES.has(d.state));
  }

  get approvedStudies() {
    return this.all.filter(d => d.type === 'study' && d.field('state') === 'Approved');
  }

  get pi() {
    const results = this.all.filter(d => d.type === 'pi');

    if (results.length === 1) {
      return results[0];
    }
  }

  get contacts() {
    return this.all.filter(d => d.type === 'contact').sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      if (aName < bName) {
        return -1
      } else if (aName > bName) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}