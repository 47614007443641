import React, { ReactNode } from 'react';
import logo from '../images/logo.svg';
import splash from '../images/splash.jpg';

interface PublicLayoutProps {
  title?: string;
  children?: ReactNode | ReactNode[];
}

export function PublicLayout(props: PublicLayoutProps) {

  return (
    <div className="main public">
      <div className="splash">
        <img src={splash} alt="" />
      </div>
      <img className="icon" src={logo} alt="CHOP Logo" />
      <article>
        <h1>{props.title}</h1>
        {props.children}
        <p className="disclaimer">
        You are authorized to use the system for approved
        purposes only, as described in the Acceptable Use of
        Technology policy. Use for any other purpose is
        prohibited. All transactional records, reports, email,
        software, and other information generated by, or
        residing upon the system are the property of CHOP.
        </p>
      </article>
    </div>
  );
}