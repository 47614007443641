import React, { useContext, useState } from "react";
import { FieldProps, Field, getFieldReadOnly } from "../Field";
import { SessionContext } from "../../App";
import { StepContext } from "../Step";
import CheckboxGroup, { CheckboxValueType } from "antd/lib/checkbox/Group";

export const CertifiedField = (props: FieldProps) => {
  const { session } = useContext(SessionContext);
  const { value, setValue } = useContext(StepContext);

  const [ defaultValue, setDefaultValue ] = useState(
    (value === true ? ["true"] : []) as CheckboxValueType[]
  );

  if (!session.document) {
    return null;
  }

  const options = [
    {
      label: "By submitting this form I certify that, to the best of my knowledge, the information supplied is complete and correct.",
      value: "true",
    },
  ];

  const onChange = (newValue: CheckboxValueType[]) => {
    if (!session.document) {
      return undefined;
    }

    setValue(!!newValue.includes("true"));
    setDefaultValue(newValue);
  };

  return (
    <Field {...props}>
      <CheckboxGroup
        options={options}
        value={defaultValue}
        disabled={getFieldReadOnly(props, session.document)}
        onChange={onChange}
      />
    </Field>
  );
}