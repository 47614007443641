import React, { useContext, useState, useEffect } from "react";
import { FieldProps, Field, getFieldReadOnly } from "../Field";
import { Upload, Button } from "antd";
import { SessionContext } from "../../App";
import { UploadFile } from "antd/lib/upload/interface";
import { postUpload } from "../../../api/postUpload";
import { deleteUpload } from "../../../api/deleteUpload";

export const FilesField = (props: FieldProps) => {
  const { session, dispatch } = useContext(SessionContext);
  const [value, setValue] = useState([] as any[]);

  useEffect(() => {
    if (session.document) {
      setValue(session.document.documents(props.name).map((doc: any) => ({
        uid: doc.seq,
        fileName: doc.name,
        name: formatFilename(doc.name),
        url: doc.uri,
        status: 'done',
      })));
    }
  }, [session.document, props.name]);

  if (!session.document) {
    return null;
  }

  const formatFilename = (filename: string) => {
    let formattedName = filename;
    if (formattedName && formattedName.length > 64) {
      formattedName = formattedName.substring(0, 31) + "..." + formattedName.substring(formattedName.length-30);
    }
    return formattedName;
  }

  const request = ({ file, onSuccess, onError }: any) => {
    if (!session.document) {
      return null;
    }
    postUpload(dispatch, session.document.idOrSeq, props.name, file)
      .then(response => response.json().then(onSuccess))
      .catch(onError);
  }

  const onChange = (info: any) => {
    let fileList = [...info.fileList];

    fileList = fileList.map(file => {
      if (file.response) {
        file.name = formatFilename(file.response.name);
        file.fileName = file.response.name;
        file.url = file.response.uri;
      }
      return file;
    });

    setValue(fileList);
  };

  const onRemove = async (file: UploadFile) => {
    if (!session.document) {
      return false;
    }
    try {
      await deleteUpload(dispatch, session.document.idOrSeq, props.name, file.uid, (file.fileName || file.name));
      return true;
    } catch (e) {
      return false;
    }
  }

  return (
    <Field {...props}>
      <Upload
        multiple={true}
        disabled={getFieldReadOnly(props, session.document)}
        customRequest={request}
        fileList={value}
        onChange={onChange}
        onRemove={onRemove}
      >
        <Button icon="upload">Upload</Button>
      </Upload>
    </Field>
  );
}