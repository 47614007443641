import { Dispatch } from 'react';
import { request } from './request';
import { SessionAction } from '../interfaces/Session';

export function postUpload(dispatch: Dispatch<SessionAction>, id: string, rel: string, file: File) {
  const fd = new FormData();
  fd.append('document', file);
  return request(dispatch, `/thicket/${id}/documents/${rel}`, {
    method: 'POST',
    body: fd,
  });
}