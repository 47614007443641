import React, { useContext } from "react";
import { FieldProps, Field, getFieldReadOnly } from "../Field";
import { SessionContext } from "../../App";
import { Select } from 'antd';
import { StepContext } from "../Step";
import { FieldOptions, FieldOptionsFn } from "../Wizard";
import { CheckboxOptionType } from "antd/lib/checkbox";

interface SelectFieldProps extends FieldProps {
  options: FieldOptions | FieldOptionsFn;
}

export const SelectField = (props: SelectFieldProps) => {
  const { session } = useContext(SessionContext);
  const { value, setValue } = useContext(StepContext);

  if (!session.document) {
    return null;
  }

  let options: CheckboxOptionType[];

  if(typeof props.options === 'function') {
    options = props.options(session.document);
  }
  else {
    options = props.options;
  }

  const onChange = (value: number) => {
    setValue(value);
  }

  return (
    <Field {...props}>
      <Select
        onChange={onChange}
        defaultValue={value}
        disabled={getFieldReadOnly(props, session.document)}
      >
        {options.map(option => <Select.Option value={option.value.toString()} >{option.label}</Select.Option>)}
      </Select>
    </Field>
  );
}
