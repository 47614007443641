import React, { useContext } from "react";
import { FieldProps, Field, getFieldReadOnly } from "../Field";
import { Radio } from "antd";
import { SessionContext } from "../../App";
import { RadioChangeEvent } from "antd/lib/radio";
import { StepContext } from "../Step";

interface YesNoFieldProps extends FieldProps {

}

export const YesNoField = (props: YesNoFieldProps) => {
  const { session } = useContext(SessionContext);
  const { value, setValue } = useContext(StepContext);

  if (!session.document) {
    return null;
  }

  let defaultValue;

  if (value === true) {
    defaultValue = "Yes";
  }

  if (value === false) {
    defaultValue = "No";
  }

  const onChange = (e: RadioChangeEvent) => {
    if (!session.document) {
      return null;
    }

    let newValue;

    switch (e.target.value) {
      case 'Yes':
        newValue = true;
        break;
      case 'No':
        newValue = false;
        break;
    }

    setValue(newValue);
  };

  return (
    <Field {...props}>
      <Radio.Group defaultValue={defaultValue} onChange={onChange} disabled={getFieldReadOnly(props, session.document)}>
        <Radio value="Yes">Yes</Radio>
        <Radio value="No">No</Radio>
      </Radio.Group>
    </Field>
  );
}