import * as React from 'react';
import { Button, Dropdown, Icon, Menu, Modal, Select } from 'antd';
import { SessionContext } from './App';
import { Link } from '../hooks/useRoutes';
import logo from '../images/logo.svg';
import { Document } from '../resources/Document';
import { postImpersonate } from '../api/postImpersonate';

export function Header() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const { session, dispatch } = React.useContext(SessionContext);

  function handleResize() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!session || !session.person) {
    return null;
  }

  const handleProfileMenuClick = (e: any) => {
    if (!session || !session.person) {
      return;
    }
    switch (e.key) {
      case 'impersonate':
        if (session && session.documents) {
          let person: Document | undefined = undefined;
          const handleOptionsFilter = (input: string, option: any): boolean => {
            if (!option || !option.props || !option.props.children) return false;
            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          };
          const onChange = (value: any) => {
            if (session.documents) {
              person = session.documents.get(value);
            }
          }
          const onOk = async () => {
            if (person) {
              await postImpersonate(dispatch, person.seq);
              dispatch({
                type: 'reset',
                impersonate: true,
              });
            }
            return;
          }
          Modal.confirm({
            onOk,
            title: "Impersonate",
            content: (
              <Select
                showSearch={true}
                allowClear={true}
                placeholder="Select a person"
                onChange={onChange}
                style={{ width: '100%' }}
                filterOption={handleOptionsFilter}
                optionFilterProp="children"
              >
                {session.documents.contacts.map(c => (
                  <Select.Option value={c.seq} key={c.seq}>{c.name + '(' + c.email + ')'}</Select.Option>
                ))}

              </Select>
            ),
          });
        }
        break;
      case 'deimpersonate':
        postImpersonate(dispatch, null).then(() => 
          dispatch({
            type: 'reset',
            impersonate: true,
          }));
        break;
      case 'profile':
        if (session.documents && session.documents.pi) {
          session.router.go('read', { id: session.documents.pi.idOrSeq });
        }
        break;
      case 'logout':
        dispatch({ type: 'reset' });
        break;
      default:
        return;
    }
  };

  const dropdownMenu = (
    <Menu onClick={handleProfileMenuClick}>
      { width <= 450 && (
        <Menu.Item key="username" disabled={true}>
          <span style={{ fontSize: '0.9em' }}>
            {session.person.name}
          </span>
        </Menu.Item>
      )}
      { width <= 450 && (
        <Menu.Divider />
      )}
      { session.documents && session.documents.pi && (
        <Menu.Item key="profile">
          <Icon type="user" />
          Edit Profile
        </Menu.Item>
      )}
      { session.person && session.person.canAdmin &&
        window.location.hostname !== "sirb.research.chop.edu" &&
        session.person.isAdmin && (
        <Menu.Item key="impersonate">
          <Icon type="user-add" />
          Impersonate
        </Menu.Item>
      )}
      { session.person && session.person.impersonating && (
          <Menu.Item key="deimpersonate">
            <Icon type="user-delete" />
            End Impersonation
          </Menu.Item>
        )
      }
      <Menu.Item key="logout">
        <Icon type="logout" />
        Logout
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="release" disabled={true}>
        <span style={{ fontSize: '0.9em' }}>Version: Development</span>
      </Menu.Item>
    </Menu>
  );

  let selectedKeys: string[] = [];
  if (session.router.current) {
    selectedKeys = [session.router.current.name];
  }

  let headerClass = '';
  let title = 'IRB';
  let extraTitle = 'Reliance Portal';

  switch (window.location.hostname) {
    case 'localhost':
    case 'cf-latest.badrabbit.com':
    case 'chirp-dev.research.chop.edu':
      extraTitle = 'RP Development';
      headerClass = 'dev-header';
      break;
    case 'chirp-dev-hotfix.research.chop.edu':
      extraTitle = 'RP Hotfix Development';
      headerClass = 'dev-header';
      break;
    case 'rescksirbqa01.research.chop.edu':
    case 'chirp-qa.research.chop.edu':
    case 'reslnchirpqa01.research.chop.edu':     
      extraTitle = 'RP QA';
      headerClass = 'dev-header';
      break;
    case 'chirp-qa-hotfix.research.chop.edu':
      extraTitle = 'RP Hotfix QA';
      headerClass = 'dev-header';
      break;
    default:
      if (session.person && session.person.realSeq === 0) {
        headerClass = 'admin-header';
        extraTitle = 'ADMIN ON PRODUCTION'
      }
      break;
  }

  return (
    <>
      <header className={ headerClass }>
       <div>
          <Link href={session.router.getUrl('home')} style={{ color: 'white' }}>
            <img
              src={logo}
              alt="[Logo]"
              width="26"
              style={{ verticalAlign: 'middle', marginRight: '0.75em' }}
            />
            <h1
              style={{ fontSize: '1.4em', fontWeight: 'bold', color: 'white', display: 'inline', position: 'relative', top: '0.1em'}}
            >
              { title } { width > 450 && extraTitle }
            </h1>
          </Link>
        </div>
        <div>
          <Dropdown overlay={dropdownMenu} trigger={['click']}>
            <Button
              icon={session.person.impersonating ? "user-add" : "user"}
              style={{
                background: 'none',
                border: 'none',
                fontWeight: 'bold',
                color: 'white',
              }}
            >
              {width > 450 && session.person.name}
              <Icon type="caret-down" />
            </Button>
          </Dropdown>
        </div>
      </header>
      <menu>
        <Menu
          mode="horizontal"
          selectedKeys={selectedKeys}
        >
          <Menu.Item key="home">
            <Icon type="home" />
            <Link href={session.router.getUrl('home')}>Home</Link>
          </Menu.Item>
          <Menu.Item key="all">
            <Icon type="profile" />
            <Link href={session.router.getUrl('all')}>All Submissions</Link>
          </Menu.Item>
        </Menu>
      </menu>
    </>
  );
}
