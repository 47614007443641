import React from 'react';
import { Routes, RouteSpec, Redirect } from "./hooks/useRoutes"
import { Home } from './components/Home';
import ResetForm from './components/ResetForm';
import { All } from './components/All';
import { Workflow } from './components/Workflow';
import { ChangePasswordForm } from './components/ChangePasswordForm';
import { WelcomeForm } from './components/WelcomeForm';
import { MFAQRCode } from './components/MFAQRCode';

export interface MyRouteSpec extends RouteSpec {
  private?: boolean;
  title?: string;
}

export const routes: Routes<MyRouteSpec> = {
  home: {
    path: '/',
    private: true,
    fn: () => <Home />,
  },
  login: {
    path: '/login',
    fn: () => <Redirect location="home" />,
  },
  welcome: {
    path: '/welcome/:token',
    title: "Welcome to CHOP IRB Reliance Portal",
    fn: ({ token }) => <WelcomeForm token={token} />,
  },
  invite: {
    path: '/account/invite',
    fn: () => <div>account invite</div>,
  },
  requestReset: {
    path: '/account/reset',
    title: "Password Recovery",
    fn: () => <ResetForm />,
  },
  handleReset: {
    path: '/account/reset/:token',
    title: "Password Recovery",
    fn: ({ token }) => <ChangePasswordForm token={token} />,
  },
  mfaToken: {
    path: '/account/mfaqrcode/:token',
    title: "MFA QR Code for CHOP IRB Reliance Portal",
    fn: ({ token }) => <MFAQRCode token={token} />,
  },
  all: {
    path: '/all',
    private: true,
    fn: () => <All tab="all" />,
  },
  allTab: {
    path: '/all/:tab',
    private: true,
    fn: ({ tab }) => <All tab={tab} />,
  },
  read: {
    path: '/:id',
    private: true,
    fn: ({ id }) => <Workflow id={id} />,
  },
  tab: {
    path: '/:id/:tab',
    private: true,
    fn: ({ id, tab }) => <Workflow id={id} tab={tab} />,
  },
  form: {
    path: '/:id/form/:page',
    private: true,
    fn: ({ id, page }) => <Workflow id={id} tab="form" page={page} />,
  },
  notFound: {
    path: '*any',
    title: "Page Not Found",
    fn: () => null,
  }
};