import React, { useContext, createContext, useReducer, useEffect, useState, useCallback } from 'react';
import { WizardContext, WizardStep, WizardField, DocumentError } from './Wizard';
import { Button, Modal } from 'antd';
import { SessionContext } from '../App';
import { FORM_TYPES } from '../../helpers/FORM_TYPES';
import { postDocument } from '../../api/postDocument';

export interface StepProps {
  name: string;
  error?: string | DocumentError;
  intro?: string;
  fields: WizardField[];
}

export interface FieldState {
  [ index: string ]: any;
}

const defaultContext = {
  value: undefined as any,
  setValue: (value: any) => {},
};

export const StepContext = createContext(defaultContext);

export const Step = ({ name, fields, intro, error }: StepProps) => {
  const { steps, setValid } = useContext(WizardContext);
  const { session, dispatch } = useContext(SessionContext);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({} as FieldState);

  const valuesReducer = (state: FieldState, update: FieldState) => {
    return {
      ...state,
      ...update,
    };
  };

  const getValues = useCallback(() => {
    const out: FieldState = {};
    if (session.document) {
      for (const field of fields) {
        out[field.name] = session.document.field(field.name);
      }
    }
    return out;
  }, [session.document, fields]);

  const [values, setValues] = useReducer(valuesReducer, getValues());

  const getShow = useCallback(() => {
    const out: FieldState = {};
    if (session.document) {
      for (const field of fields) {
        if (typeof field.show === 'boolean') {
          out[field.name] = field.show;
        } else if (typeof field.show === 'function') {
          out[field.name] = field.show.call(undefined, session.document);
        } else {
          out[field.name] = 'default';
        }
      }
    }
    return out;
  }, [session.document, fields]);

  const getRequired = useCallback(() => {
    const out: FieldState = {};
    const show = getShow();
    if (session.document) {
      for (const field of fields) {
        if (!show[field.name]) {
          out[field.name] = false;
        } else if (typeof field.required === 'boolean') {
          out[field.name] = field.required;
        } else if (typeof field.required === 'function') {
          out[field.name] = field.required.call(undefined, session.document);
        }
      }
    }
    return out;
  }, [session.document, fields, getShow]);

  const getErrors = useCallback(() => {
    const out: FieldState = {};
    const required = getRequired();
    if (session.document) {
      for (const field of fields) {
        if (field.validate) {
          out[field.name] = !field.validate.call(undefined, {
            value: values[field.name],
            document: session.document,
          });
        }
        if (!out[field.name] && required[field.name]) {
          if (Array.isArray(values[field.name])) {
            out[field.name] = values[field.name].length === 0;
          } else {
            out[field.name] = !values[field.name] && values[field.name] !== false;
          }
        }
      }
    }
    return out;
  }, [session.document, fields, values, getRequired]);

  const hasTrue = (arg: { [ index: string ]: any }) => Object.values(arg).includes(true);

  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    if (session.document && !initDone) {
      setValues(getValues());
      setValid(!hasTrue(getErrors()));
      setInitDone(true);
    }
  }, [session.document, getValues, getErrors, setValid, initDone]);

  if (!session.document) {
    return null;
  }

  const id = session.router.current.params.id;
  const page = session.router.current.params.page;
  const thisStep = steps.find(s => s.name === name);
  let thisIndex = -1;
  let prevStep: WizardStep | undefined = undefined;
  let nextStep: WizardStep | undefined = undefined;

  if (thisStep) {
    thisIndex = steps.indexOf(thisStep);
    prevStep = steps[thisIndex - 1];
    nextStep = steps[thisIndex + 1];
  } else {
    return null;
  }

  const goBack = () => {
    if (prevStep) {
      session.router.go('form', { id, page: prevStep.name });
    }
  };

  const goNext = () => {
    const errors = getErrors();
    setErrors(errors);
    if (session.document && nextStep && !Object.values(errors).includes(true)) {
      if (session.document.editable) {
        setSaving(true);
        const fields = {
          ...values
        };
        postDocument(dispatch, session.document.idOrSeq, fields).then(response => response.json().then((data) => {
          if (!session.document || !nextStep) return null;
          dispatch({
            dispatch,
            type: 'documentData',
            document: data,
          });
          setValid(!hasTrue(errors));
          setSaving(false);
          session.router.go('form', { id, page: nextStep.name });
        })).catch(() => {
          if (!session.document) return null;
          setSaving(false);
          Modal.error({
            title: 'Connection Error',
            content: 'There was an error saving your form to the server. Please try again later.',
          });
        });
      } else {
        session.router.go('form', { id, page: nextStep.name });
      }
    } else {
      Modal.error({
        title: 'Error',
        content: getError() || 'Your form contains errors and could not be saved. Please resolve the errors and retry.',
      });
    }
  };

  const goSubmit = () => {
    const errors = getErrors();
    setErrors(errors);
    if (session.document && !Object.values(errors).includes(true)) {
      const fields = {
        ...values,
        state: 'Pending Submission',
        hasBeenSubmittedOnce: true,
        editable: false,
      };
      session.document.field('state', 'Pending Submission');
      session.document.field('hasBeenSubmittedOnce', true);
      session.document.field('editable', false);
      postDocument(dispatch, session.document.idOrSeq, fields).then(() => {
        if (!session.document) return null;
        Modal.success({
          title: `${FORM_TYPES[session.document.type]} Submitted`,
          content: `The document for ${session.document.name} has been submitted. Please check back soon to review your submission status.`,
          onOk: () => session.router.go('home'),
        });
      }).catch(() => {
        if (!session.document) return null;
        session.document.field('state', 'Submission Failed');
        session.document.field('editable', true);
        Modal.error({
          title: 'Connection Error',
          content: 'There was an error submitting your form to the server. Please try again later.',
        });
      });
    } else {
      console.log(session.document, errors, session.document);
      Modal.error({
        title: 'Error',
        content: 'Your form contains errors and could not be submitted. Please resolve the errors and resubmit.',
      });

    }
  };

  const goExit = () => {
    session.router.go('read', { id });
  };

  const makeSetValue = (name: string) =>
    (value: any) => {
      if (session.document) {
        session.document.field(name, value);
        setValues({ [name]: value });
      }
    };

  const getError = () => {
    if (typeof error === 'function' && session.document) {
      return error(session.document);
    }
    return error;
  };

  const show = getShow();
  const required = getRequired();

  // Only render the current step
  if (name === page) {
    return (
      <div style={{ margin: '2em 0', display: name === page ? 'block' : 'none' }}>
        {intro && <p>{intro}</p>}
        <form>
          {fields.map((field) => {
            if (!session.document) {
              return null;
            }
            const Field = field.field;
            return show[field.name] && (
              <StepContext.Provider key={field.name} value={{ value: values[field.name], setValue: makeSetValue(field.name) }}>
                <Field {...field} required={required[field.name]} error={errors[field.name]} readOnly={field.readOnly || !session.document.editable}/>
              </StepContext.Provider>
            );
          })}
        </form>
        <div className="form-field">
          <div className="form-label" />
          <div className="form-value align-right">
            {nextStep && <Button icon="fullscreen-exit" onClick={goExit}>Exit</Button>}
            {prevStep && <Button icon="backward" onClick={goBack}>Back</Button>}
            {nextStep && <Button disabled={saving} icon={saving ? 'loading' : 'forward'} type="primary" onClick={goNext}>{session.document.editable && 'Save & '}Continue</Button>}
            {!nextStep && <Button disabled={!session.document.editable} icon="cloud-upload" type="primary" onClick={goSubmit}>Submit {FORM_TYPES[session.document.type]}</Button>}
          </div>
        </div>
      </div>
    );
  }
  return null;
};
