import React from "react";
import { TextField } from "../components/Wizard/fields/TextField";
import { YesNoField } from "../components/Wizard/fields/YesNoField";
import { TextAreaField } from "../components/Wizard/fields/TextAreaField";
import { CheckboxField } from "../components/Wizard/fields/CheckboxField";
import { SelectField } from "../components/Wizard/fields/SelectField";
import { LinkedDocuments } from "../components/Wizard/fields/LinkedDocuments";
import { FilesField } from "../components/Wizard/fields/FilesField";
import { WizardStep } from "../components/Wizard/Wizard";
import { CertifiedField } from "../components/Wizard/fields/CertifiedField";
import { phoneRegex, emailRegex } from "../helpers/regex";
import { CheckboxOptionType } from "antd/lib/checkbox";
import { Document } from "../resources/Document";

export const studyWizard: WizardStep[] = [
  {
    name: "info",
    title: "Study Information",
    error: "If this information is not accurate, please contact the Children’s Hospital of Philadelphia Principal Investigator or study coordinator with questions.",
    fields: [
      {
        name: "chop_studyTitle",
        label: "Study Name",
        readOnly: true,
        field: TextField,
      },
      {
        name: "pi",
        label: "Local PI",
        field: LinkedDocuments,
      },
      {
        name: "institution",
        label: "Institution(s)",
        readOnly: true,
        field: LinkedDocuments,
      },
      {
        name: "changePI",
        label: "Do you wish to change the PI for this study?",
        field: YesNoField,
      },
      {
        name: "newPI",
        label: "Select new PI",
        field: SelectField,
        required: true,
        options: (document: Document) => [
          ...document.piChoices,
          {
            label: <strong><em>Request a PI not on this list</em></strong>,
            value: 'create',
          },
        ],
        show: document => document.field('changePI'),
      },
      {
        name: "newPI_FirstName",
        label: "New PI First Name",
        field: TextField,
        required: true,
        show: document => document.field('changePI') && document.field('newPI') === 'create',
      },
      {
        name: "newPI_LastName",
        label: "New PI Last Name",
        field: TextField,
        required: true,
        show: document => document.field('changePI') && document.field('newPI') === 'create',
      }, {
        name: "newPI_Phone",
        label: "New PI Phone Number",
        field: TextField,
        required: true,
        show: document => document.field('changePI') && document.field('newPI') === 'create',
        validate: ({ value }) => !value || phoneRegex.test(value),
      },
      {
        name: "newPI_Email",
        label: "New PI Email",
        field: TextField,
        required: true,
        show: document => document.field('changePI') && document.field('newPI') === 'create',
        validate: ({ value }) => !value || emailRegex.test(value),
      },
      {
        name: "localContext",
        label: "Please indicate any additional local context issues",
        field: TextAreaField,
      },
      {
        name: "comments",
        label: "Comments",
        field: TextAreaField,
      },
    ],
  },
  {
    name: "coordinator",
    title: "Study Coordinator",
    error: "Please enter a valid name and email address",
    fields: [
      {
        name: "coordinatorName",
        label: "Name",
        field: TextField,
        validate: ({ value, document }) => {
          if ((value && document.field("coordinatorEmail")) || (!value)) {

            return true;
          }
          return false;
        },
      },
      {
        name: "coordinatorPhone",
        label: "Phone",
        field: TextField,
        validate: ({ value, document }) => {
          if (((phoneRegex.test(value)) && (document.field("coordinatorName") && document.field("coordinatorEmail"))) || (!value)) {
            return true;
          }
          return false;
        },
      },
      {
        name: "coordinatorEmail",
        label: "Email",
        field: TextField,
        validate: ({ value, document }) => {
          if (((value && document.field("coordinatorName")) && (!value || emailRegex.test(value))) || (!value)) {
            return true;
          }
          return false;
        },
      }
    ],
  },
  {
    name: "plan",
    title: "Site Specific Research Plan",
    fields: [
      {
        name: "recruitmentMethods",
        label: "Indicate all recruitment methods that will be used for this study",
        required: true,
        field: TextAreaField,
      },
      {
        name: "consentProcess",
        label: "Describe the consent process for research participants",
        help: "(timing, location, individuals involved)",
        required: true,
        field: TextAreaField,
      },
      {
        name: "reimbursements",
        label: "List the amount of reimbursements for expenses and compensation payments for research participants",
        help: "List the payment to parents separately from payments to subjects",
        field: TextAreaField,
      },
      {
        name: "nonEnglishSubjects",
        label: "Does the investigator anticipate enrolling limited English proficiency subjects?",
        required: true,
        field: YesNoField,
      },
      {
        name: "nonEnglishConsentMethod",
        label: "Which methods will be used for obtaining consent?",
        help: "(use of Short Form in requested language with a Study Summary Document)",
        clear: true,
        required: true,
        field: CheckboxField,
        show: d => !!d.field("nonEnglishSubjects"),
        options: [
          {
            label: "Short Form Consent Process",
            value: "com.webridge.entity.Entity[OID[691187C6D7EE81469C72CD5FDABB9274]]",
          },
          {
            label: "Translated consent form",
            value: "com.webridge.entity.Entity[OID[78AA6C50168A824AA5DA3D6B0C6D5C23]]",
          }
        ]
      }

    ],
  },
  {
    name: "team",
    title: "Investigative Team",
    fields: [
      {
        name: "teamTrainingCompliance",
        label: "Confirm that all study team members are in compliance with local institutional training requirements for human subjects research",
        help: "(e.g. CITI training and/or other institution-specific training)",
        required: true,
        field: YesNoField,
      },
      {
        name: "teamCOIMembers",
        label: "Provide the names of study team members with a Confict of Interest related to this research",
        field: TextAreaField,
      },
    ],
  },
  {
    name: "attachments",
    title: "Attachments",
    fields: [
      {
        name: "coiPlan",
        label: "Attach Conflict of Interest management plans",
        help: "when study team members have conflicts with the current research",
        field: FilesField,
      },
      {
        name: "consentForm",
        label: "Attach the site-specific informed consent form",
        help: <em>if applicable</em>,
        field: FilesField,
      },
      {
        name: "recruitmentMaterials",
        label: "Attach the site-specific recruitment materials",
        help: <em>if applicable</em>,
        field: FilesField,
      },
      {
        name: "researchPolicies",
        label: "Attach research policies relevant to the IRB",
        help: <em>if applicable</em>,
        field: FilesField,
      },
      {
        name: "coverLetter",
        label: "Attach a cover letter / response to IRB stipulations cover letter, if applicable",
        help: <em>if applicable</em>,
        field: FilesField,
      },
    ],
  },
  {
    name: "submit",
    title: "Certify & Submit",
    fields: [
      {
        name: "changesExplanation",
        field: TextAreaField,
        label: "Which changes were made to the study?",
        clear: true,
        required: true,
        show: d => d.state === 'Action Required',
      },
      {
        name: "certified",
        label: "Certification",
        required: true,
        validate: ({ value }) => !!value,
        field: CertifiedField,
      }
    ],
  },
];
