import React, { useState } from 'react';
import { FilterDropdownProps } from 'antd/lib/table';
import { DatePicker, Button } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

export interface DateDropdownProps extends FilterDropdownProps {
  showTime?: boolean;
}

export function DateDropdown(props: DateDropdownProps) {

  const {
    setSelectedKeys,
    confirm,
    showTime,
    clearFilters,
  } = props;

  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();

  const onSubmit = () => {
    const antFilterKeys = date1 && date2
                          ? [date1.valueOf().toString(), date2.valueOf().toString()]
                          : [];
    setSelectedKeys && setSelectedKeys(
      antFilterKeys,
    );
    confirm && confirm();
  };

  const onDateRangeChange = (dates: RangePickerValue, dateString: string[]) => {
    const [date1, date2] = dates;
    setDate1(date1);
    setDate2(date2);
  };

  const reset = () => {
    setDate1(undefined);
    setDate2(undefined);
    clearFilters && clearFilters([]);
  };

  return (
    <div style={{ padding: 8 }}>
      <div style={{ paddingBottom: 8 }}>
        <DatePicker.RangePicker
          value={[date1, date2]}
          style={{ width: '100%' }}
          showTime={showTime ? { format: 'HH:mm' } : false}
          onChange={onDateRangeChange}
        />
      </div>
      <Button
        type="primary"
        onClick={onSubmit}
        icon="search"
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={reset} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  );
}
