import React, { useContext, useState, useEffect } from "react";
import { FieldProps, Field } from "../Field";
import { SessionContext } from "../../App";
import { Link } from "../../../hooks/useRoutes";
import { Document } from "../../../resources/Document";
import { List } from "antd";

export const LinkedDocuments = (props: FieldProps) => {
  const { session } = useContext(SessionContext);
  const [value, setValue] = useState([] as Document[]);

  useEffect(() => {
    if (session.document) {
      setValue(session.document.rel(props.name));
    }
  }, [session.document, props.name]);

  if (!session.document) {
    return null;
  }

  const renderLink = (d: Document) => (
    <List.Item key={d.seq}>
      <Link href={session.router.getUrl('read', { id: d.idOrSeq })}>{ d.name }</Link>
    </List.Item>
  );

  return (
    <Field {...props}>
      <List
        bordered={true}
        size="small"
        dataSource={value}
        renderItem={renderLink} />
    </Field>
  );
}