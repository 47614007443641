import { Dispatch } from 'react';
import { Document } from "../resources/Document";
import { SessionAction, Session } from "../interfaces/Session";
import { postChild } from "../api/postChild";
import { message, Modal} from "antd";

interface CreateRelatedProps {
  document: Document;
  child: {
    type: string;
    rel: string;
    copy: boolean;
    prefix: string;
    fields: any;
  };
  dispatch: Dispatch<SessionAction>;
  session: Session<any>;
}

export const createRelated = ({ document, child, dispatch, session }: CreateRelatedProps) => {
  Modal.confirm({
    title: `Create ${child.prefix} for ${document.name}?`,
    onOk: async () => {
      postChild(dispatch, document.seq, child).then(r => r.json().then(data => {
        dispatch({
          dispatch,
          type: 'documentData',
          document: data
        });
        message.success(`${data.fields.name} created.`);
        session.router.go('read', { id: data.seq });
        return;
      })).catch(e => {
        message.error(`There was an error creating the ${child.prefix}.`)
        return;
      });
    },
  });
};