import { statusTag } from '../../helpers/statusTag';

export const dateOptions = {
  // timeZone: 'UTC',
  // weekday: 'short',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export const dateTimeOptions = {
  ...dateOptions,
  hour: '2-digit',
  minute: '2-digit',
};

export enum DATA_TYPES {
  string = 'string',
  date = 'date',
  dateTime = 'dateTime',
  status = 'status',
  boolean = 'boolean',
  stringArray = 'stringArray',
  number = 'number',
}

export interface FieldSchema {
  title: string;
  type?: DATA_TYPES;
  sortable?: boolean;
  optional?: boolean;
  filterable?: boolean;
  hidden?: boolean;
  options?: string[];
  customOptions?: boolean;
}

export interface ResourceSchema {
  [index: string]: FieldSchema;
}

const dateRenderer = (dateValue: number) => {
  if (dateValue) {
    const date = new Date(dateValue);
    return isNaN(date.getTime()) ? 'No Date Recorded' : date.toLocaleString('en-US', dateOptions);
  }
  return 'No Date Recorded';
};

export const dateTimeRenderer = (dateValue: number) => {
  if (dateValue) {
    const date = new Date(dateValue);
    return isNaN(date.getTime()) ? 'No Date Recorded' :
      date.toLocaleString('en-US', dateTimeOptions);
  }
  return 'No Date Recorded';
};

const booleanRenderer = (value: boolean) => {
  return value ? 'Yes' : 'No';
};

const stringArrayRenderer = (value: string[]) => {
  return (value || []).join(', ');
};

export enum columnTypes {
  dateColumn = 'dateColumn',
  categoriesColumn = 'categoriesColumn',
  requirementsColumn = 'requirementsColumn',
}

export const columnRenderers: { [ index: string ]: Function } = {
  [DATA_TYPES.date]: dateRenderer,
  [DATA_TYPES.dateTime]: dateTimeRenderer,
  [DATA_TYPES.status]: statusTag,
  [DATA_TYPES.boolean]: booleanRenderer,
  [DATA_TYPES.stringArray]: stringArrayRenderer,
};

export const isColumnVisible = (dataIndex: string, query: any, schema: ResourceSchema) => {
  // if query contains column info, use query. Else use schema.
  if (query.columns && query.columns.hasOwnProperty(dataIndex)) {
    return query.columns[dataIndex] === 'true';
  }
  return !(schema[dataIndex] && schema[dataIndex].hidden);
};

export function filterTable(
  requestFilters: any,
  responseData: any,
  schema: ResourceSchema,
) {
  let filteredResponse = responseData;
  if (requestFilters) {
    for (const field in requestFilters) {
      if (schema[field].filterable !== false) {
        filteredResponse = filteredResponse.filter((row: any) => {
          let rowField = row[field];
          if (typeof rowField === 'boolean') {
            rowField = rowField ? 'Yes True' : 'No False';
          }
          switch (schema[field].type || DATA_TYPES.string) {
            case DATA_TYPES.stringArray:
              if (typeof requestFilters[field] !== 'object') {
                requestFilters[field] = [requestFilters[field]];
              }
              {
                const regexes: RegExp[] = requestFilters[field]
                                          .map((text: string) => RegExp(text, 'i'));
                const results = regexes.flatMap(r => (rowField || []).map(
                  (row: string) => r.test(row),
                ));
                return results.includes(true);
              }
            case DATA_TYPES.dateTime:
            case DATA_TYPES.date:
              // create two date objects
              // one at the start of the start day and one at the end of the end day
              const itemDate = new Date(rowField);
              const startDate = new Date(parseInt(requestFilters[field][0], 10));
              const endDate = new Date(parseInt(requestFilters[field][1], 10));
              if (schema[field].type === DATA_TYPES.date) {
                startDate.setHours(0);
                startDate.setMinutes(0);
                startDate.setMilliseconds(0);
                endDate.setHours(23);
                endDate.setMinutes(59);
                endDate.setMilliseconds(59999);
              }
              return itemDate.getTime() >= startDate.getTime()
                && itemDate.getTime() <= endDate.getTime();
            default:
              if (typeof requestFilters[field] !== 'object') {
                requestFilters[field] = [requestFilters[field]];
              }
              let rowFieldString = '';
              if (typeof rowField === 'object') {
                if (rowField.props) {
                  rowFieldString = rowField.props.children;
                } else {
                  rowFieldString = '';
                }
              } else {
                rowFieldString = rowField;
              }
              const regexes = requestFilters[field].map((text: string) => RegExp(text, 'i'));
              const results = regexes.map((r: RegExp) => r.test(rowFieldString || ''));
              return results.includes(true);
          }
        });
      }
    }
  }
  return filteredResponse;
}

export function makeSort(
  field: string,
  type: DATA_TYPES,
  invert: boolean,
) {
  return function (a: any, b: any) {
    let result;

    let left = a[field];
    let right = b[field];

    // eIRB_00004289 	sIRB Attempting to sort by title results in trim not supported error
    if (typeof(left) === 'object') {
      left = left.props.children;
    }
    if (typeof(right) === 'object') {
      right = right.props.children;
    }

    if (type === DATA_TYPES.stringArray) {
      left = left[0];
      right = right[0];
    }

    if (type === DATA_TYPES.stringArray || type === DATA_TYPES.string) {
      left = (left || 'ZZZZZ').trim().toLowerCase();
      right = (right || 'ZZZZZ').trim().toLowerCase();
      result = left.localeCompare(right);

    } else if (left > right) {
      result = 1;
    } else if (left < right) {
      result = -1;
    } else {
      result = 0;
    }
    if (invert) {
      result *= -1;
    }
    return result;
  };
}
export function sortTable(
  requestSort: any,
  responseData: any,
  schema: ResourceSchema,
) {
  let sortedResponse = responseData;
  const sortSpec = requestSort || { date: 'descend' };
  for (const field in sortSpec) {
    if (schema[field] && schema[field].sortable !== false) {
      sortedResponse = sortedResponse.sort(
        makeSort(
          field,
          schema[field].type || DATA_TYPES.string,
          sortSpec[field] === 'descend',
        ),
      );
    }
  }
  return sortedResponse;
}

interface FilterSortParams {
  sort: any;
  filters: any;
}

export function filterAndSort(
  params: FilterSortParams,
  data: any,
  schema: ResourceSchema,
) {
  return sortTable(params.sort, filterTable(params.filters, data, schema), schema);
}
