import React, { useContext } from "react";
import { FieldProps, Field, getFieldReadOnly } from "../Field";
import { Radio, Button } from "antd";
import { SessionContext } from "../../App";
import { StepContext } from "../Step";
import { CheckboxOptionType } from "antd/lib/checkbox";
import { RadioChangeEvent } from "antd/lib/radio";

interface OptionsFieldProps extends FieldProps {
  options: string[] | CheckboxOptionType[];
}

export const RadioField = (props: OptionsFieldProps) => {
  const { session } = useContext(SessionContext);
  const { value, setValue } = useContext(StepContext);

  if (!session.document) {
    return null;
  }

  const onClear = () => setValue([]);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  }

  return (
    <Field {...props}>
      <Radio.Group
        options={props.options}
        value={value}
        disabled={getFieldReadOnly(props, session.document)}
        onChange={onChange}
      />
      { props.clear && <div><Button size="small" onClick={onClear}>Clear</Button></div>}
    </Field>
  );
}