import React, { ReactNode } from "react";
import { Document, DocumentPredicate } from "../../resources/Document";

export interface FieldProps {
  name: string;
  required?: boolean;
  error?: boolean;
  clear?: boolean;
  readOnly?: boolean | DocumentPredicate;
  label: string;
  help?: string;
  children?: ReactNode | ReactNode[];
}

export function getFieldReadOnly(field: FieldProps, document: Document) {
  if (typeof field.readOnly === 'boolean') {
    return field.readOnly;
  } else if (typeof field.readOnly === 'function') {
    return field.readOnly.call(undefined, document);
  }
}

export const Field = ({ required, error, label, help, children }: FieldProps) => {
  return (
    <div className="form-field">
      <div className={ error ? "form-label form-error" : "form-label" }>
        <label>
          { required && <span className="form-required">* </span> }
          { label }
        </label>
        { help && <div className="form-help">{ help }</div> }
      </div>
      <div className="form-value">
        { children }
      </div>
    </div>
  )
}
