import { Document } from "../resources/Document";

export const hasOpenAmendments = (amendments: Document[]) => {
    var openAmendment = false;
    for (var i=0; !openAmendment && i<amendments.length; i++) {
      switch (amendments[i].state) {
        case "Disapproved":
        case "Approved":
        case "Exempted":
        case "Acknowledged":
        case "Exemption Granted":
        case "Withdrawn":
          break;
        default:
          openAmendment = true;
          break;
      }
    }
    return openAmendment;
};

export const hasOpenReviews = (reviews: Document[]) => {
    var openReview = false;
    for (var i=0; !openReview && i<reviews.length; i++) {
      switch (reviews[i].state) {
        case "Approved":
        case "Complete":
        case "Withdrawn":
            break;
        default:
            openReview = true;
            break;
      }
    }
    return openReview;
};