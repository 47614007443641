import { TextField } from "../components/Wizard/fields/TextField";
import { WizardStep } from "../components/Wizard/Wizard";
import { LinkedDocuments } from "../components/Wizard/fields/LinkedDocuments";
import { RadioField } from "../components/Wizard/fields/RadioField";
import { TextAreaField } from "../components/Wizard/fields/TextAreaField";
import { FilesField } from "../components/Wizard/fields/FilesField";
import { CertifiedField } from "../components/Wizard/fields/CertifiedField";
import { phoneRegex, zipRegex, emailRegex } from "../helpers/regex";
import { Document } from "../resources/Document";

export const piWizard: WizardStep[] = [
  {
    name: "profile",
    title: "Principal Investigator Profile",
    error: (d: Document) => {
      const errors = [];
      if (!d.field('degrees')) {
        errors.push('Degrees must be specified.');
      }
      if (!d.field('title')) {
        errors.push('Appointment/Title must be specified.');
      }
      const value = d.field('years');
      if (value && !(/^\d*$/.test(value))) {
        errors.push('Years field must be numeric.');
      }
      return errors.join(' ');
    },
    fields: [
      {
        name: "name",
        label: "Name",
        readOnly: true,
        field: TextField,
      },
      {
        name: "institution",
        label: "Institution(s)",
        field: LinkedDocuments,
      },
      {
        name: "degrees",
        label: "Degree(s)",
        required: true,
        field: TextField,
      },
      {
        name: "title",
        label: "Appointment/Title",
        required: true,
        field: TextField,
      },
      {
        name: "years",
        label: "Years conducting pharmaceutical or other FDA-regulated clinical research trials",
        field: TextField,
        validate: ({ value }) => !value || /^\d*$/.test(value),
      },
      {
        name: "specialist",
        field: RadioField,
        label: "Pediatric and/or Adult specialist?",
        clear: true,
        options: [
          { label: "Pediatric", value: "com.webridge.entity.Entity[OID[3D08293C5CF1C84D9D5BDBB591A44288]]" },
          { label: "Adult", value: "com.webridge.entity.Entity[OID[A7C3A224FA1AE14B98DA32A37E66C317]]" },
          { label: "Both", value: "com.webridge.entity.Entity[OID[32C3D14570336449AE21C1DD6AD13598]]" }
        ]
      },
      {
        name: "specialties",
        field: TextAreaField,
        label: "Other Specialties",
      },
    ],
  },
  {
    name: "address",
    title: "Principal Investigator Address",
    error: "10-digit Phone, Address Line 1, City, State, and Zip are all required",
    fields: [
      {
        name: "email",
        label: "Email",
        field: TextField,
        readOnly: true,
        //validate: (v: string) => emailRegex.test(v),
      },
      {
        name: "phone",
        label: "Phone",
        required: true,
        field: TextField,
        validate: ({ value }) => phoneRegex.test(value),
      },
      {
        name: "address1",
        label: "Address Line 1",
        required: true,
        field: TextField,
      },
      {
        name: "address2",
        label: "Address Line 2",
        help: "Company Name / PO Box",
        field: TextField,
      },
      {
        name: "address3",
        label: "Address Line 3",
        field: TextField,
      },
      {
        name: "city",
        label: "City",
        required: true,
        field: TextField,
      },
      {
        name: "piState",
        label: "State",
        required: true,
        field: TextField,
      },
      {
        name: "zip",
        label: "ZIP",
        required: true,
        field: TextField,
        validate: ({ value }) => zipRegex.test(value),
      },
    ]
  },
  {
    name: "contact",
    title: "Administrative Contact Address",
    intro: "The Administrative Contact is optional. This individual will not be copied on notifications or have access to the IRB information.  The CHOP IRB will use this information if assistance is needed to communicate with the Principal Investigator. Contact information for a Study Coordinator can be added in the specific Study Submission.",
    error: "Please enter a valid name and email address",
    fields: [
      {
        name: "contactName",
        label: "Name",
        field: TextField,
        validate: ({ value, document }) => {
          if ((value && document.field("contactEmail")) || (!value)) {

            return true;
          }
          return false;
        },
      },
      {
        name: "contactEmail",
        label: "Email",
        field: TextField,
        validate: ({ value, document }) => {
          if (((value && document.field("contactName")) && (!value || emailRegex.test(value))) || (!value)) {
            return true;
          }
          return false;
        },
      },
      {
        name: "contactPhone",
        label: "Phone",
        field: TextField,
        validate: ({ value, document }) => {
          if (((phoneRegex.test(value)) && (document.field("contactName") && document.field("contactEmail"))) || (!value)) {
            return true;
          }
          return false;
        },
      },
      {
        name: "contactAddress1",
        label: "Address Line 1",
        field: TextField,
      },
      {
        name: "contactAddress2",
        label: "Address Line 2",
        help: "Company Name / PO Box",
        field: TextField,
      },
      {
        name: "contactAddress3",
        label: "Address Line 3",
        field: TextField,
      },
      {
        name: "contactCity",
        label: "City",
        field: TextField,
      },
      {
        name: "contactState",
        label: "State",
        field: TextField,
      },
      {
        name: "contactZip",
        label: "ZIP",
        field: TextField,
        validate: ({ value }) => !value || zipRegex.test(value),
      },
    ],
  },
  {
    name: "documents",
    title: "Documents",
    intro: "Attach related files under the appropriate section. Allowed file types: PDF, Word, PowerPoint, Excel, OpenDocument, Keynote, ZIP.",
    error: "Invalid file type. Allowed file types: PDF, Word, PowerPoint, Excel, OpenDocument, Keynote, ZIP.",
    fields: [
      {
        name: "document",
        label: "Attach any relevant documents",
        field: FilesField,
      },
    ],
  },
  {
    name: "submit",
    title: "Certify & Submit",
    fields: [
      {
        name: "certified",
        label: "Certification",
        required: true,
        validate: ({ value }) => !!value,
        field: CertifiedField,
      }
    ],
  },
];