import React from "react";
import { Tag, Icon } from "antd";

export const statusTag = (value: string, record: any) => {
  const labelColor = value === 'Open' ? 'green'
                      : value === 'Approved' ? 'green'
                      : value === 'In Review' ? 'blue'
                      : value === 'Pre-Review' ? 'orange'
                      : value === 'Action Required' ? 'orange'
                      : value === 'Changes Requested' ? 'orange'
                      : value === 'Pre-Submission' ? 'orange'
                      : value === 'Pending Submission' ? 'red'
                      : value === 'Submission Failed' ? 'red'
                      : 'gray';

  const styles = {
    fontSize: '1.1em',
  };

  return (
    <Tag color={labelColor} style={styles} key="state">
      {value || 'Default'}
      { record.editable ? null : <> <Icon type="lock" /></>}
    </Tag>
  );
};
