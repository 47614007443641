export const FORM_TYPES = {
  cr: 'Continuing Review',
  study: 'Study',
  pi: 'Principal Investigator',
  sc: 'Study Coordinator',
  revent: 'Reportable Event',
  contact: 'Contact',
  history: 'History',
  institution: 'Institution',
  amendment: 'Amendment',
};

export const FORM_TYPE_ICONS = {
  cr: 'flag',
  study: 'experiment',
  pi: 'user',
  sc: 'user',
  revent: 'alert',
  contact: 'user',
  history: 'edit',
  institution: 'apartment',
  amendment: 'reconciliation',
};

export const FORM_TYPE_ICON_COLORS = {
  cr: '#d4aa00',
  study: '#00aad4',
  pi: '#8800aa',
  sc: '#8800aa',
  revent: '#c91822',
  contact: 'grey',
  history: 'grey',
  institution: 'grey',
  amendment: '#2ca02c',
};