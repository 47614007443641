import React, { createContext, useContext, useEffect, useReducer, ComponentType, useState, ReactElement } from "react";
import { Steps } from "antd";
import { SessionContext } from "../App";
import { Step } from "./Step";
import { Document, DocumentPredicate } from "../../resources/Document";
import { CheckboxOptionType } from "antd/lib/checkbox";

export interface ValidateProps { 
  value: any,
  document: Document,
}

type ValidatePredicate = (props: ValidateProps) => boolean;
export type DocumentError = (d: Document) => string;

export type FieldOptions = CheckboxOptionType[];
export type FieldOptionsFn = (d: Document) => FieldOptions;

export interface WizardField {
  name: string;
  required?: boolean | DocumentPredicate;
  show?: boolean | DocumentPredicate;
  validate?: ValidatePredicate;
  readOnly?: boolean | DocumentPredicate;
  clear?: boolean;
  label: string;
  help?: string | ReactElement;
  options?: FieldOptions | FieldOptionsFn;
  field: ComponentType<any>;
}

export interface WizardStep {
  name: string;
  title: string;
  error?: string | DocumentError;
  intro?: string;
  fields: WizardField[];
}

interface WizardProps {
  steps: WizardStep[];
}

interface ValidState {
  [ index: string ]: boolean;
}

interface WizardContext {
  steps: WizardStep[];
  valid: ValidState;
  setValid: (value: boolean) => void;
};

const defaultContext = {
  steps: [],
  valid: {},
  setValid: (value: boolean) => {},
}

export const WizardContext = createContext<WizardContext>(defaultContext);

export const Wizard = ({ steps }: WizardProps) => {
  const { session } = useContext(SessionContext);
  const [ validList, setValidList ] = useState([] as boolean[]);

  const id = session.router.current.params.id;
  const page = session.router.current.params.page;
  const step = steps.find(s => s.name === page);
  const index = step ? steps.indexOf(step) : -1;

  const defaultValidState: ValidState = {};

  const validReducer = (state: ValidState, update: ValidState) => {
    return {
      ...state,
      ...update,
    };
  };

  const [valid, setValid] = useReducer(validReducer, defaultValidState);

  const onChange = (i: number) => {
    session.router.go('form', { id, page: steps[i].name }, { query: { tab: session.router.query.tab }})
  }

  const makeSetValid = (name: string) =>
    (value: boolean) => {
      setValid({[name]: value});
    }

  useEffect(() => {
    let prevPage;
    const step = steps.find(s => s.name === session.router.current.params.page);
    if (step) {
      const index = steps.indexOf(step);
      prevPage = steps[index-1] ? steps[index-1].name : undefined;
    }
    if (prevPage && valid[prevPage] === false) {
      session.router.go('read', { id: session.router.current.params.id });
    }
    setValidList(steps.map(step => valid[step.name]));
    
  }, [session, valid, steps]);

  return (
    <div className="wizard">
      <Steps
        direction="vertical"
        current={index}
        onChange={onChange}
      >
        {steps.map((step, i) => (
          <Steps.Step
            key={step.name}
            title={step.title}
            disabled={validList.slice(0, i).includes(false)}
            description={(
              <WizardContext.Provider key={step.name} value={{steps, valid, setValid: makeSetValid(step.name) }}>
                <Step {...step} />
              </WizardContext.Provider>
            )}
          />
        ))}
      </Steps>
    </div>
  );
}