import React, { useContext, ReactElement } from 'react';
import { Tabs } from 'antd';
import { SessionContext } from './App';
import { Params } from '../hooks/useRoutes';

interface TabSpec {
  name: string,
  default?: boolean,
  key?: string,
  component?: ReactElement,
}

interface TabMenuProps {
  tabPosition?: 'left' | 'top';
  location: string;
  defaultLocation: string;
  urlParams?: Params;
  tab: string;
  tabs: TabSpec[];
}

export const TabMenu = ({ tabPosition, location, defaultLocation, urlParams, tab, tabs }: TabMenuProps) => {
  const { session } = useContext(SessionContext);
  const defaultTab = tabs.find(t => t.default);

  const onChange = (tab: string) => {
    if (defaultTab && tab === defaultTab.key) {
      session.router.go(defaultLocation, urlParams || {}, { ...session.router.query });
    } else {
      session.router.go(location, { ...urlParams, tab }, { ...session.router.query });
    }
  };

  const tabPanes = tabs.map((t, i) => (
    <Tabs.TabPane tab={t.name} key={t.key || t.name}>
      {(t.component) ? t.component : <span>No related data found</span>}
    </Tabs.TabPane>
  ));

  return (
    <Tabs
      tabBarGutter={0}
      activeKey={tab || tabs[0].key || tabs[0].name}
      defaultActiveKey={tabs[0].key || tabs[0].name}
      tabPosition={tabPosition || 'top'}
      destroyInactiveTabPane={true}
      onChange={onChange}
    >
      {tabPanes}
    </Tabs>
  );
};