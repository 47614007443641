import { Dispatch } from 'react';
import { request } from './request';
import { SessionAction } from '../interfaces/Session';

interface PostResetProps {
  username: string,
}

export function postSendMFAToken(dispatch: Dispatch<SessionAction>, props: PostResetProps) {
  return request(dispatch, '/auth/sendMFAToken', {
    auth: false,
    method: 'POST',
    body: {
      ...props,
    },
  });
}
