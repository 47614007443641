import React, { useContext } from 'react';
import qs from 'qs';
import Route from 'route-parser';
import { EventEmitter } from 'events';
import { SessionContext } from '../components/App';

export type Params = { [ index: string]: string };

export interface RouteSpec {
  path: string;
  fn?: (params: Params) => any;
}

interface RouteResult<T> {
  name: string;
  spec: T;
  params: Params;
  result?: any;
}

export interface RouteState<T> {
  go: (name: string, params?: Params, options?: GoOptions) => void;
  getUrl: (name: string, params?: Params, options?: URLOptions) => string | undefined;
  query: any;
  current: RouteResult<T>;
}

export type Routes<T> = { [ index: string ]: T };

interface URLOptions {
  query?: any,
}

interface GoOptions extends URLOptions {
  replace?: boolean;
}

class RouteEmitter extends EventEmitter {};

const routeEmitter = new RouteEmitter();

export function useRoutes<T extends RouteSpec>(routes: Routes<T>, prefix = "") {
  const routeObjects: { [ index: string ]: Route } = {};

  for (const route in routes) {
    routeObjects[route] = new Route(routes[route].path);
  }

  const getUrl = (name: string,
               params: Params = {},
               { query,
               }: URLOptions = {}) =>
  {
    if (routeObjects[name]) {
      const route = routeObjects[name];
      let path = prefix + route.reverse(params || {});
      if (query) {
        const queryString = qs.stringify(query, { allowDots: true });
        if (queryString) {
          path += '?' + queryString;
        }
      }
      return path;
    }
  }

  const go = (name: string, params: Params = {}, options: GoOptions = {}) =>
  {
    const path = getUrl(name, params, options);
    navigate(path || '', options.replace);
  }

  const getState: () => RouteState<T> = () => {
    const path = window.location.pathname.replace(prefix, "");
    const query = qs.parse(window.location.search, { allowDots: true, ignoreQueryPrefix: true });
    for (const name in routes) {
      const spec = routes[name];
      const route = routeObjects[name];
      const params = route.match(path);
      if (params !== false) {
        return {
          go, 
          getUrl,
          query,
          current: {
            path,
            name,
            spec,
            params,
            result: spec.fn ? spec.fn.call(spec, params) : undefined,
          },
        };
      }
    }
    throw new Error('unhandled location');
  };

  const [current, setCurrent] = React.useState(getState());

  const onChange = (event?: PopStateEvent) => setCurrent(getState());

  React.useEffect(() => {
    window.addEventListener('popstate', onChange);
    routeEmitter.on('event', onChange);

    return () => {
      window.removeEventListener('popstate', onChange);
      routeEmitter.removeListener('event', onChange);
    }
  });

  return current;
}

export function navigate(path: string, replace = false) {
  setImmediate(() => {
    if (replace) {
      window.history.replaceState({}, path, path);
    } else {
      window.history.pushState({}, path, path);
    }
    routeEmitter.emit('event');
  });
}

export function setLinkProps(props: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!event.shiftKey && !event.ctrlKey && !event.altKey) {
      if (event.currentTarget instanceof HTMLAnchorElement) {
        event.preventDefault();
        navigate(event.currentTarget.href);
      }
      if (props.onClick) {
        props.onClick(event);
      }
    }
  };
  return {...props, onClick};
}

export const Link = (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => <a {...setLinkProps(props)}>{ props.children }</a>;

interface RedirectProps {
  location: string,
  query?: any,
  [ index: string ]: any,
}

export const Redirect = (props: RedirectProps) => {
  const { session } = useContext(SessionContext);
  session.router.go(props.location, props, { query: props.query });
  return null;
}