import React, { useContext, ChangeEvent } from "react";
import { FieldProps, Field, getFieldReadOnly } from "../Field";
import { SessionContext } from "../../App";
import TextArea from "antd/lib/input/TextArea";
import { StepContext } from "../Step";

interface TextAreaFieldProps extends FieldProps {

}

export const TextAreaField = (props: TextAreaFieldProps) => {
  const { session } = useContext(SessionContext);
  const { value, setValue } = useContext(StepContext);

  if (!session.document) {
    return null;
  }

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!session.document) {
      return null;
    }

    setValue(e.target.value);
  }

  return (
    <Field {...props}>
      <TextArea
        name={props.name}
        readOnly={getFieldReadOnly(props, session.document)}
        defaultValue={value}
        onChange={onChange}
      />
    </Field>
  );
}