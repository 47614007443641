import { WizardStep } from "../components/Wizard/Wizard";
import { RadioField } from "../components/Wizard/fields/RadioField";
import { FilesField } from "../components/Wizard/fields/FilesField";
import { TextAreaField } from "../components/Wizard/fields/TextAreaField";
import { CertifiedField } from "../components/Wizard/fields/CertifiedField";

export const reventWizard: WizardStep[] = [
  {
    name: "type",
    title: "Reportable Event Type",
    error: "Type must be specified.",
    fields: [
      {
        name: "subtype",
        field: RadioField,
        label: "Reportable Event Type",
        options: [
          { label: "Prospective Protocol Deviation", value: "ppd" },
          { label: "Initial Serious Adverse Event (SAE)", value: "sae" },
          { label: "Protocol Deviation that has already occurred", value: "pd" },
          { label: "Other", value: "other" },
        ]
      },
      {
        name: "deviationDocument",
        field: FilesField,
        label: "Provide approval documentation from the person responsible for the overall study conduct (lead site," + 
        " sponsor, medical monitor, or steering committee chair) for this prospective deviation.",
        show: d => d.field("subtype") === "ppd",
      }
    ],
  },
  {
    name: "description",
    title: "Reportable Event Description",
    fields: [
      {
        name: "eventDescription",
        field: TextAreaField,
        required: true,
        label: "General Description",
      }
    ],
  },
  {
    name: "response",
    title: "Investigator Assessment/Comments",
    fields: [
      {
        name: "response",
        field: TextAreaField,
        label: "Investigator Asessment/Comments",
        required: true,
        help: "Please provide additional information as relevant (information concerning e.g. the corrective plan, negative effects experienced by subjects or others, impact of the prospective changes on the acceptability of the risks, need for an amendment, etc., as appropriate)."
      }
    ]
  },
  {
    name: "documents",
    title: "Documents",
    intro: "Attach related files (including a cover letter / response to IRB stipulations cover letter, if applicable) under the appropriate section. Allowed file types: PDF, Word, PowerPoint, Excel, OpenDocument, Keynote, ZIP.",
    error: "Invalid file type. Allowed file types: PDF, Word, PowerPoint, Excel, OpenDocument, Keynote, ZIP.",
    fields: [
      {
        name: "document",
        label: "Attach any relevant documents",
        field: FilesField,
      },
    ],
  },
  {
    name: "submit",
    title: "Certify & Submit",
    fields: [
      {
        name: "certified",
        label: "Certification",
        required: true,
        validate: ({ value }) => !!value,
        field: CertifiedField,
      }
    ],
  },
];