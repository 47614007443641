import React, { useContext } from 'react';
import { Table, Button, Icon, Tag, Select, Form } from 'antd';
import { TableProps, FilterDropdownProps, PaginationConfig } from 'antd/lib/table';
import { columnRenderers, isColumnVisible, dateTimeOptions, dateOptions, DATA_TYPES, ResourceSchema, filterAndSort } from './helpers';
import { DateDropdown, DateDropdownProps } from './FilterRenderers/DateDropdown';
import { SessionContext } from '../App';
import { Link } from '../../hooks/useRoutes';

const { Option } = Select;

export interface SubmissionTableProps extends TableProps<any> {
  key: string;
  dataSource: any[];
  filterCategory?: boolean;
}

export interface QueryStore {
  tab?: string;
  page?: number;
  pageSize?: number;
  filters?: {
    [index: string]: string[];
  };
  sort?: {
    [index: string]: string;
  };
  columns?: {
    [index: string]: boolean;
  };
}

export function SubmissionTable (props: SubmissionTableProps) {
  let statusOptions: string[] = [];

  for (const { state } of props.dataSource) {
    if (state && !statusOptions.includes(state)) {
      statusOptions.push(state);
    }
  }

  statusOptions = statusOptions.sort();

  const getRowLink = (rowValue: any) => {
    return session.router.getUrl('read', { id: rowValue.key });
  }

  const schema: ResourceSchema = {
    title: {
      title: 'Title',
    },
    type: {
      title: 'Category',
      options: [
        'Amendment',
        'Continuing Review',
        'Principal Investigator',
        'Reportable Event',
        'Study',
      ],
      filterable: !!props.filterCategory,
    },
    state: {
      title: 'Status',
      type: DATA_TYPES.status,
      options: statusOptions,
    },
    date: {
      title: 'Date',
      type: DATA_TYPES.date,
    },
    action: {
      title: 'Action',
    },
  };

  const { session } = useContext(SessionContext);

  const getDateColumnProps = (column: any) => ({
    filterDropdown: (props: FilterDropdownProps) => <DateDropdown {...props} />,
  })

  const getDateTimeColumnProps = (column: any) => ({
    filterDropdown: (props: DateDropdownProps) => <DateDropdown showTime={true} {...props} />,
  })

  const getStringColumnProps = (column: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => {
      const search = () => confirm && confirm();
      const onChange = (e: any) => setSelectedKeys && setSelectedKeys(e || []);
      const reset = () => clearFilters && clearFilters([]);
      const options = (
        schema[column.dataIndex].options ||
        (schema[column.dataIndex].type === DATA_TYPES.boolean ?
          ['Yes', 'No'] :
          [])
      ).map((option: string) => <Option key={option}>{option}</Option>);
      const mode = schema[column.dataIndex].customOptions === false
        ? 'multiple'
        : 'tags';
      return (
        <Form layout="inline" style={{ padding: '8px' }}>
          <Form.Item style={{ marginRight: '8px' }}>
            <Select
              mode={mode}
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${schema[column.dataIndex].title}`}
              value={selectedKeys || []}
              onChange={onChange}
              allowClear={true}
              style={{ width: '250px' }}
            >
            {options}
            </Select>
          </Form.Item>
          <Form.Item style={{ marginRight: '8px' }}>
            <Button
              type="primary"
              onClick={search}
              icon="search"
            />
          </Form.Item>
          <Form.Item style={{ marginRight: 0 }}>
            <Button onClick={reset} icon="close" />
          </Form.Item>
        </Form>
      );
    },
  })

  const getColumnSearchProps = (column: any) => {
    if (column.filterable === false) {
      return {};
    }

    let filterProps;
    switch (column.type) {
      case (DATA_TYPES.dateTime):
        filterProps = getDateTimeColumnProps(column);
        break;
      case (DATA_TYPES.date):
        filterProps = getDateColumnProps(column);
        break;
      default: // string
        filterProps = getStringColumnProps(column);
        break;
    }
    return ({
      ...filterProps,
      filterIcon: (filtered: boolean) => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInput && searchInput.focus());
        }
      },
    });
  }

  const schemaColumns = schema ? Object.entries(schema).map(([key, value]) => {
    const col = value as any;
    const newColumn = {
      ...col,
      dataIndex: key,
      render: columnRenderers[col.type],
      sorter: true, // col.sortable !== false,
      sortOrder: session.router.query.sort ? session.router.query.sort[key] : (key === 'date' && 'descend'),
      filteredValue: session.router.query.filters ? session.router.query.filters[key] : [],
      // onHeaderCell: (column: any) => ({
      //   width: column.width,
      //   onResize: this.handleResize(index),
      // }),
    };
    return {
      ...newColumn,
      ...getColumnSearchProps(newColumn),
    };
  }) : [];

  const columns = schemaColumns.filter((col: any) => {
    return isColumnVisible(col.dataIndex, session.router.query, schema);
  });

  const titleToLink = (row: any) => ({
    ...row,
    title: <Link href={getRowLink(row)}>{ row.title }</Link>,
    children: row.children && row.children.length > 0 && row.children.map(titleToLink),
  })

  const dataSource = props.dataSource.map(titleToLink);

  const getTableOptions: () => TableProps<any> = () => {
    const { page, pageSize } = session.router.query;
    return {
      scroll: { x: true },
      bordered: false,
      // components: { header: { cell: ResizableHeader } },
      size: 'small',
      columns,
      onChange: handleTableChange,
      defaultExpandAllRows: true,
      dataSource: filterAndSort(session.router.query, dataSource, schema),
      pagination: {
        pageSize: pageSize ? parseInt(pageSize, 10) : undefined,
        current: page ? parseInt(page, 10) : undefined,
        defaultPageSize: 20,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
      },
    };
  };

  if (!session || !session.person) {
    return null;
  }

  let searchInput: Select<string[]> | null;

  const setTableState = (newState: QueryStore) => {
    const query = { ...session.router.query, ...newState };
    session.router.go(session.router.current.name, session.router.current.params, { query });
  }

  function handleTableChange (pagination: PaginationConfig, filters: any, sorter: any) {
    setTableState({
      filters,
      page: pagination.current,
      pageSize: pagination.pageSize,
      sort: sorter.field && { [sorter.field]: sorter.order },
    });
  }

  const handleColumnChange = (newColumn: QueryStore['columns']) => {
    setTableState({
      columns: {
        ...session.router.query.columns,
        ...newColumn,
      },
    });
  }

  const getColumnTags = (columns: any[]) => {
    return columns.filter(c => c.optional !== false).map((c, i) => {
      const handleChange = (checked: boolean) => {
        const newColumn = {
          [c.dataIndex]: checked,
        };
        handleColumnChange(newColumn);
      };
      return (
        <Tag.CheckableTag
          key={c.dataIndex}
          checked={isColumnVisible(c.dataIndex, session.router.query, schema)}
          onChange={handleChange}
        >
          {c.title}
        </Tag.CheckableTag>
      );
    });
  }

  const clearFilter = (key: string, index?: number) => {
    const newFilters = {
      ...session.router.query.filters,
    };

    if (index === undefined) {
      newFilters[key] = [];
    } else {
      newFilters[key] = newFilters[key].filter((f: string, i: number) => i !== index);
    }

    const newQuery = {
      ...session.router.query,
      filters: newFilters,
    };

    setTableState(newQuery);
  }

 const makeFilterTag = (key: string, text: string, onClose: Function) => {
    return (
      <Tag key={key} closable={true} onClose={onClose} visible={true}>
        {text}
      </Tag>
    );
  }

  const filterTags = [];
  if (schema && session.router.query.filters) {
    for (const [key, val] of Object.entries(session.router.query.filters)) {
      if (val && (val as string[]).length && schema[key]) {
        if (schema[key].type === DATA_TYPES.date) {
          const text = (val as string[])
            .map((datetime) => {
              return new Date(parseInt(datetime, 10))
                .toLocaleDateString('en-US', dateOptions);
            })
            .join(' - ');
          const filterText = `${schema[key].title}: ${text}`;
          const onClose = () => clearFilter(key);
          filterTags.push(makeFilterTag(key, filterText, onClose));
        } else if (schema[key].type === DATA_TYPES.dateTime) {
          const text = (val as string[])
            .map((datetime) => {
              return new Date(parseInt(datetime, 10))
                .toLocaleDateString('en-US', dateTimeOptions);
            })
            .join(' - ');
          const filterText = `${schema[key].title}: ${text}`;
          const onClose = () => clearFilter(key);
          filterTags.push(makeFilterTag(key, filterText, onClose));
        } else {
          (val as string[]).forEach((text, i) => {
            const filterText = `${schema[key].title}: "${text}"`;
            const onClose = () => clearFilter(key, i);
            filterTags.push(makeFilterTag(`${key}:${i}`, filterText, onClose));
          });
        }

      }
    }
  }

  return (
    <>
      {filterTags.length ? <p><strong>Filters: </strong> {filterTags} </p> : undefined}
      <p style={{ lineHeight: '2em' }}><strong>Columns: </strong>
        {getColumnTags(schemaColumns)}
      </p>
      <Table {...getTableOptions()} />
    </>
  );
}