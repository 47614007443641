import { Dispatch } from 'react';
import { request } from './request';
import { SessionAction } from '../interfaces/Session';

interface PostTokenProps {
  username: string,
  password: string,
  personName: string,
  token: string,
}

export function postPasswordChange(dispatch: Dispatch<SessionAction>, props: PostTokenProps) {
  return request(dispatch, '/auth/token', {
    auth: false,
    method: 'POST',
    body: {
      ...props,
      grant_type: 'invitation',
    },
  });
}